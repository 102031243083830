@media all and (min-width: 2000px) {
  .first__block .slider__item .slider__text--wrap {
    left: 550px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .second__block .left__side--image {
    max-width: 100%;
  }
}

@media all and (max-width: 1700px) {
  .third__block #products__owl {
    max-width: 90%;
    margin: 0 auto;
    margin-bottom: 60px;
  }
  .third__block #products__owl .owl-nav .owl-prev {
    left: -100px;
  }
  .third__block #products__owl .owl-nav .owl-next {
    right: -100px;
  }
  .fourth__block .advantage__item {
    margin-right: 19px;
  }
  .page__inner .inner__banner {
    padding-left: 250px;
  }
  .page__inner .content__block .left__side {
    padding: 100px 150px 20px 200px;
  }
  .page__inner .content__block .left__side .more__button {
    margin-bottom: 50px;
  }
  .page__inner .content__block:nth-child(even) .left__side {
    padding: 170px 250px 20px 150px;
  }
  .page__inner .products__cat--wrap .product__cat {
    max-width: 345px;
    margin-right: 20px;
  }
  .page__inner .products__cat--wrap .wp-pagenavi a {
    margin-right: 35px;
  }
  .page__inner .products__cat--wrap .wp-pagenavi .current {
    margin-right: 35px;
  }
  .page__inner .products__cat--wrap .wp-pagenavi .previouspostslink {
    margin-right: 50px;
  }
  .page__inner .products__all--wrap .product__cat {
    width: 32%;
    max-width: 460px;
  }
  .page__inner .contacts__inner .contacts__info--wrap form .wpcf7-form-control-wrap {
    max-width: 295px;
  }
  .page__inner .contacts__inner .contacts__info--wrap form .wpcf7-form-control-wrap:first-child {
    margin-right: 17px;
  }
  .page__inner .news__inner .news__wrap .wp-pagenavi a {
    margin-right: 35px;
  }
  .page__inner .news__inner .news__wrap .wp-pagenavi .current {
    margin-right: 35px;
  }
  .page__inner .news__inner .news__wrap .wp-pagenavi .previouspostslink {
    margin-right: 50px;
  }
  .page__inner .problems__inner .wp-pagenavi a {
    margin-right: 35px;
  }
  .page__inner .problems__inner .wp-pagenavi .current {
    margin-right: 35px;
  }
  .page__inner .problems__inner .wp-pagenavi .previouspostslink {
    margin-right: 50px;
  }
}

@media all and (max-width: 1590px) {
  header .sub__menu--banner {
    padding: 30px 0 30px 150px;
  }
  header .header--logo {
    margin-right: 230px;
  }
  .first__block .slider__item .slider__text--wrap {
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .third__block #products__owl .owl-nav .owl-prev {
    left: -80px;
  }
  .third__block #products__owl .owl-nav .owl-next {
    right: -80px;
  }
  .fifth__block .news__item .thumb__wrap {
    height: 400px;
  }
  footer .footer__column {
    margin-right: 120px;
  }
  .page__inner .inner__banner {
    padding-left: 200px;
  }
  .page__inner .content__block .left__side {
    padding: 100px 130px 20px 150px;
  }
  .page__inner .content__block:nth-child(even) .left__side {
    padding: 170px 170px 20px 100px;
  }
  .page__inner .contacts__inner .contacts__info--wrap .left {
    margin-right: 50px;
  }
  .page__inner .contacts__inner .contacts__info--wrap .contacts__cards--wrap .card {
    padding: 40px 40px 35px 60px;
  }
  .page__inner .contacts__inner .contacts__info--wrap form .wpcf7-form-control-wrap {
    width: 50%;
    max-width: 297.8px;
  }
  .page__inner .contacts__inner .contacts__info--wrap form .wpcf7-form-control-wrap input {
    padding: 15px 0 15px 25px;
  }
  .page__inner .contacts__inner .contacts__info--wrap form .wpcf7-form-control-wrap:first-child {
    margin-right: 5px;
  }
  .page__inner .contacts__inner .contacts__info--wrap form .wpcf7-form-control-wrap:nth-child(3) textarea {
    padding-left: 25px;
  }
  .page__inner .single__inner .post__content img.alignleft {
    max-width: 600px;
    margin: 50px 70px 70px 0;
  }
  .page__inner .single__inner .post__content img.alignright {
    max-width: 600px;
    margin: 50px 0 70px 70px;
  }
  .page__inner .single__inner .prod__navigation--wrap a {
    font-size: 16px;
  }
  .page__inner .problems__inner .problem__block {
    padding: 60px 75px 60px 500px;
    background: #4d4f8c url(../img/logo-p.svg) 127% 100% no-repeat;
    background-size: 400px;
  }
  .page__inner .problems__inner .problem__block img {
    width: 450px;
    height: 485px;
  }
  .page__inner .single__problems--inner .content__wrap .left__image {
    margin-right: 50px;
  }
  .page__inner .single__problems--inner .prod__navigation--wrap a {
    font-size: 16px;
  }
  .error404 .error__inner .page__wrap h1 {
    font-size: 250px;
  }
  .error404 .error__inner .page__wrap p {
    margin-bottom: 35px;
  }
}

@media all and (max-width: 1480px) {
  .fourth__block .advantage__item {
    width: 24%;
    max-width: 322px;
    margin-right: 20px;
  }
  footer .footer__column {
    margin-right: 100px;
  }
  .page__inner .bottom__links--wrap .bottom__item {
    max-width: 300px;
    height: 290px;
    padding: 50px 35px;
  }
  .page__inner .bottom__links--wrap .bottom__item:hover {
    padding-bottom: 30px;
  }
  .page__inner .single__product .sidebar .small__images a {
    max-width: 200px;
  }
  .page__inner .products__cat--wrap {
    padding: 60px 0 0;
  }
  .page__inner .products__cat--wrap .product__cat {
    max-width: 320px;
  }
  .page__inner .products__cat--wrap .wp-pagenavi a {
    margin-right: 30px;
  }
  .page__inner .products__cat--wrap .wp-pagenavi .current {
    margin-right: 30px;
  }
  .page__inner .products__cat--wrap .wp-pagenavi .previouspostslink {
    margin-right: 45px;
  }
  .page__inner .single__product {
    padding: 80px 0 0;
  }
  .page__inner .news__inner .news__wrap .wp-pagenavi a {
    margin-right: 30px;
  }
  .page__inner .news__inner .news__wrap .wp-pagenavi .current {
    margin-right: 30px;
  }
  .page__inner .news__inner .news__wrap .wp-pagenavi .previouspostslink {
    margin-right: 45px;
  }
  .page__inner .problems__inner .wp-pagenavi a {
    margin-right: 30px;
  }
  .page__inner .problems__inner .wp-pagenavi .current {
    margin-right: 30px;
  }
  .page__inner .problems__inner .wp-pagenavi .previouspostslink {
    margin-right: 45px;
  }
  .page__inner .products__all--wrap .product__cat {
    max-width: 430px;
  }
  .page__inner .contacts__inner .contacts__info--wrap form .wpcf7-form-control-wrap {
    max-width: 283px;
  }
}

@media all and (max-width: 1400px) {
  header {
    padding: 20px 0;
  }
  header .sub__menu--banner {
    top: 83px;
    width: calc(100% - 300px);
    padding: 20px 0 20px 100px;
  }
  header .header--logo {
    margin-right: 200px;
  }
  header .language {
    width: 300px;
  }
  header .language .hidden__lang {
    bottom: -160px;
  }
  header .language .hidden__lang li a {
    padding: 35px 0 30px 70px;
  }
  nav ul li {
    margin-right: 50px;
  }
  nav ul li:after {
    margin-top: 10px;
  }
  nav ul li .sub-menu {
    margin-top: 30px;
    padding: 40px 30px;
    width: 300px;
    left: -90px;
  }
  .first__block {
    height: calc(100vh - 110px);
    margin-top: 115px;
  }
  .first__block .slider__item {
    height: calc(100vh - 110px);
  }
  .first__block .slider__item .slider__text--wrap {
    left: 220px;
  }
  .first__block .slider__item .slider__text--wrap .mark {
    margin-bottom: 20px;
  }
  .first__block .slider__item .slider__text--wrap h4 {
    font-size: 54px;
    line-height: 1.3;
  }
  .first__block .slider__item .slider__text--wrap .slider__undertitle {
    margin-bottom: 30px;
  }
  .first__block #banner__owl .owl-nav .owl-prev {
    left: 80px;
  }
  .first__block #banner__owl .owl-nav .owl-next {
    right: 80px;
  }
  .third__block #products__owl {
    width: 100%;
  }
  .third__block .slider__item .bottom__bg {
    height: 120px;
  }
  .fourth__block .advantage__item {
    padding: 30px;
    max-width: 270px;
  }
  .fifth__block .news__item .thumb__wrap {
    height: 300px;
  }
  footer .footer__logo {
    margin-right: 100px;
  }
  footer .footer__column {
    margin-right: 70px;
  }
  .page__inner {
    margin-top: 115px;
  }
  .page__inner .inner__banner {
    padding-left: 150px;
  }
  .page__inner .content__block .left__side {
    padding: 100px 70px 20px 70px;
  }
  .page__inner .content__block .left__side .more__button {
    margin-bottom: 50px;
  }
  .page__inner .content__block:nth-child(even) .right__side {
    padding: 100px 0;
  }
  .page__inner .content__block:nth-child(even) .left__side {
    padding: 120px 70px 20px 70px;
  }
  .page__inner .bottom__links--wrap .bottom__item {
    max-width: 270px;
    height: 260px;
    padding: 50px 35px;
  }
  .page__inner .bottom__links--wrap .bottom__item:hover {
    padding-bottom: 30px;
  }
  .page__inner .documentation__wrap .documentation__table tr:nth-child(2) td {
    width: 99.3%;
  }
  .page__inner .documentation__wrap .documentation__table tr th:first-child {
    width: 36.5%;
  }
  .page__inner .documentation__wrap .documentation__table tr th:nth-child(2) {
    width: 36.5%;
  }
  .page__inner .documentation__wrap .documentation__table tr th:nth-child(3) {
    width: 13%;
  }
  .page__inner .documentation__wrap .documentation__table tr th:nth-child(4) {
    width: 16%;
  }
  .page__inner .documentation__wrap .documentation__table tr th:nth-child(4) a:before {
    right: 20px;
  }
  .page__inner .documentation__wrap .documentation__table--second tr td:first-child {
    width: 36.5%;
  }
  .page__inner .documentation__wrap .documentation__table--second tr td:nth-child(2) {
    width: 36.5%;
  }
  .page__inner .documentation__wrap .documentation__table--second tr td:nth-child(3) {
    width: 13%;
  }
  .page__inner .documentation__wrap .documentation__table--second tr td:nth-child(4) {
    width: 16%;
  }
  .page__inner .documentation__wrap .documentation__table--second tr td:nth-child(4) a:before {
    right: 20px;
  }
  .page__inner .auctioneer__page .documentation__table tr:nth-child(2) td {
    width: 99.4%;
  }
  .page__inner .auctioneer__page .documentation__table tr th:first-child {
    width: 64.4%;
  }
  .page__inner .auctioneer__page .documentation__table tr th:nth-child(2) {
    width: 13%;
  }
  .page__inner .auctioneer__page .documentation__table tr th:nth-child(3) {
    width: 17.6%;
  }
  .page__inner .auctioneer__page .documentation__table tr th:nth-child(3):before {
    right: 30px;
  }
  .page__inner .auctioneer__page .documentation__table--second tr td:first-child {
    width: 64.4%;
  }
  .page__inner .auctioneer__page .documentation__table--second tr td:nth-child(2) {
    width: 13%;
  }
  .page__inner .auctioneer__page .documentation__table--second tr td:nth-child(3) {
    width: 17.6%;
  }
  .page__inner .single__product .sidebar .sp-thumbnails-container .sp-thumbnail:before, .page__inner .single__product .sidebar .sp-thumbnails-container .sp-thumbnail:after {
    width: 50px;
  }
  .page__inner .single__product .sidebar .small__images a {
    max-width: 170px;
  }
  .page__inner .single__product .sidebar .small__images a:before, .page__inner .single__product .sidebar .small__images a:after {
    width: 50px;
  }
  .page__inner .products__cat--wrap .tabs li.current {
    padding-left: 40px;
  }
  .page__inner .products__cat--wrap .tabs li.current:before {
    left: 20px;
  }
  .page__inner .products__cat--wrap .product__cat {
    max-width: 265px;
  }
  .page__inner .products__all--wrap .product__cat {
    height: 250px;
    max-width: 360px;
  }
  .page__inner .contacts__inner .contacts__info--wrap form .wpcf7-form-control-wrap {
    max-width: 236.8px;
  }
  .page__inner .resources__inner .resources__tabs--wrap .res__tab {
    height: 350px;
  }
  .page__inner .problems__inner .team__member {
    margin-bottom: 150px;
  }
  .error404 .error__inner {
    height: calc(100vh - 83.6px);
  }
  .error404 .error__inner .page__wrap {
    height: calc(100vh - 83.6px);
  }
}

@media all and (max-width: 1300px) {
  header .header--logo {
    margin-right: 150px;
  }
  .second__block .right__side--content {
    padding-top: 40px;
  }
  .second__block .right__side--content .block__number {
    margin-bottom: 30px;
  }
  .second__block .right__side--content .right__block--title {
    margin-bottom: 30px;
  }
  .second__block .right__side--content .content__titile {
    margin-bottom: 30px;
  }
  .second__block .right__side--content .about__undertitle {
    margin-bottom: 30px;
  }
  .second__block .right__side--content p {
    margin-bottom: 30px;
  }
}

@media all and (max-width: 1050px) {
  header .sub__menu--banner {
    width: calc(100% - 200px);
  }
  header .header--logo {
    margin-right: 100px;
  }
  header .language {
    width: 200px;
    padding-left: 35px;
  }
  header .language .hidden__lang {
    top: -140px;
  }
  header .language .hidden__lang li a {
    padding: 30px 0 25px 34px;
  }
  nav ul li {
    margin-right: 40px;
  }
  nav ul li .sub-menu li {
    font-size: 14px;
    margin-bottom: 15px;
  }
  .first__block .slider__item .slider__text--wrap {
    left: 200px;
  }
  .first__block #banner__owl .owl-nav .owl-next {
    right: 60px;
  }
  .first__block #banner__owl .owl-nav .owl-prev {
    left: 60px;
  }
  .third__block #products__owl .owl-nav .owl-next {
    right: -70px;
  }
  .third__block #products__owl .owl-nav .owl-prev {
    left: -70px;
  }
  .fourth__block .advantage__item {
    margin-right: 15px;
    max-width: 223px;
  }
  .fourth__block .advantage__item .title {
    font-size: 14px;
  }
  .fifth__block .news__item {
    width: calc(35% - 20px);
    margin-right: 20px;
  }
  .fifth__block .news__item .thumb__wrap {
    height: 250px;
  }
  .fifth__block .news__item .description__wrap {
    padding: 30px 0 40px;
  }
  .fifth__block .news__item .description__wrap .date-title__wrap {
    padding-right: 25px;
  }
  .fifth__block .news__item .description__wrap .post__text--wrap {
    padding: 0 25px;
  }
  .page__inner .inner__banner {
    padding-left: 100px;
  }
  .page__inner .inner__banner ul.breadcrumbs {
    margin-bottom: 20px;
  }
  .page__inner .inner__banner h1 {
    font-size: 56px;
  }
  .page__inner .content__block .left__side {
    padding: 50px 50px 20px;
  }
  .page__inner .content__block .left__side .more__button {
    margin-bottom: 30px;
  }
  .page__inner .content__block .left__side h4 {
    margin-bottom: 30px;
  }
  .page__inner .content__block .left__side p {
    margin-bottom: 30px;
  }
  .page__inner .content__block:nth-child(even) .right__side {
    padding: 50px 0;
  }
  .page__inner .content__block:nth-child(even) .left__side {
    padding: 50px 50px 20px;
  }
  .page__inner .bottom__links--wrap {
    padding: 100px 0;
  }
  .page__inner .bottom__links--wrap .bottom__item {
    max-width: 220px;
    height: 250px;
    padding: 30px 20px;
    overflow: hidden;
  }
  .page__inner .bottom__links--wrap .bottom__item:hover {
    padding-bottom: 30px;
  }
  .page__inner .bottom__links--wrap .bottom__item h4 {
    margin-bottom: 20px;
  }
  .page__inner .bottom__links--wrap .bottom__item p {
    line-height: 1.5;
  }
  .page__inner .single__product {
    padding: 90px 0 0;
  }
  .page__inner .single__product .sidebar .sp-thumbnails-container .sp-thumbnail:before, .page__inner .single__product .sidebar .sp-thumbnails-container .sp-thumbnail:after {
    width: 30px;
  }
  .page__inner .single__product .sidebar .small__images a {
    max-width: 140px;
  }
  .page__inner .single__product .sidebar .small__images a:before, .page__inner .single__product .sidebar .small__images a:after {
    width: 35px;
  }
  .page__inner .single__product .sidebar .specifications .specifications__accordeon .accordeon__content {
    padding: 30px 30px 40px 40px;
  }
  .page__inner .single__product .main__content .advantages .advantages__item .left {
    padding: 40px 0 0 20px;
  }
  .page__inner .single__product .main__content .advantages .advantages__item .right {
    padding: 40px 40px 40px 45px;
  }
  .page__inner .single__product .prod__navigation--wrap a {
    font-size: 16px;
  }
  .page__inner .products__cat--wrap .product__cat {
    width: 50%;
    max-width: 325px;
  }
  .page__inner .products__cat--wrap .product__cat:nth-child(3n) {
    margin-right: 20px;
  }
  .page__inner .products__cat--wrap .product__cat:nth-child(2n) {
    margin-right: 0;
  }
  .page__inner .products__all--wrap .product__cat {
    height: 250px;
    max-width: 360px;
    margin-right: 18px;
  }
  .page__inner .contacts__inner #map {
    height: 600px;
  }
  .page__inner .contacts__inner .contacts__info--wrap .left {
    margin-right: 30px;
  }
  .page__inner .contacts__inner .contacts__info--wrap .contacts__cards--wrap .card {
    padding: 40px 15px 35px 55px;
  }
  .page__inner .contacts__inner .contacts__info--wrap form .wpcf7-form-control-wrap {
    width: 48%;
  }
  .page__inner .contacts__inner .contacts__info--wrap form .wpcf7-form-control-wrap:first-child {
    margin-right: 12px;
  }
  .page__inner .team__inner .team__member {
    padding: 55px 110px 60px 350px;
  }
  .page__inner .team__inner .team__member img {
    width: 340px;
    height: 455px;
    left: -20px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap .point__logo {
    width: 250px;
    height: 70px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap .point__logo img {
    max-width: 130px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.ternopol {
    top: 240px;
    left: 200px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.ternopol:hover {
    top: 200px;
    left: 180px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.vinnitsya {
    top: 310px;
    left: 350px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.vinnitsya:hover {
    top: 270px;
    left: 335px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.kiev {
    top: 220px;
    left: 430px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.kiev:hover {
    top: 180px;
    left: 410px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.nikolaev {
    bottom: 260px;
    left: 525px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.nikolaev:hover {
    bottom: 300px;
    left: 510px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.poltava {
    top: 250px;
    right: 340px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.poltava:hover {
    right: 355px;
    top: 210px;
  }
  .page__inner .news__inner .news__wrap .news__item .description__wrap .left__author {
    margin-right: 50px;
  }
  .page__inner .single__inner .post__content img.alignleft {
    max-width: 450px;
    margin: 50px 50px 50px 0;
  }
  .page__inner .single__inner .post__content img.alignright {
    max-width: 450px;
    margin: 50px 0 50px 50px;
  }
  .page__inner .problems__inner .problem__block {
    padding: 50px 60px 50px 350px;
    background: #4d4f8c url(../img/logo-p.svg) 135% 100% no-repeat;
    background-size: 400px;
  }
  .page__inner .problems__inner .problem__block img {
    width: 330px;
    height: 500px;
  }
  .page__inner .resources__inner .resources__tab--item .documentation__table tr th:before {
    right: 15px;
  }
  .page__inner .resources__inner .resources__tab--item .left__image {
    margin-right: 40px;
  }
  .page__inner .resources__inner .resources__tab--item .right__content {
    padding-top: 30px;
  }
  .page__inner .resources__inner .resources__tab--item .right__content p {
    margin-bottom: 30px;
  }
  .page__inner .documentation__wrap .documentation__table tr:nth-child(2) td {
    width: 99.2%;
  }
  .page__inner .documentation__wrap .documentation__table tr th {
    padding-left: 20px;
  }
  .page__inner .documentation__wrap .documentation__table tr th:nth-child(2) {
    width: 34.5%;
  }
  .page__inner .documentation__wrap .documentation__table tr th:nth-child(4) {
    width: 18%;
  }
  .page__inner .documentation__wrap .documentation__table--second tr td:nth-child(2) {
    width: 34.5%;
  }
  .page__inner .documentation__wrap .documentation__table--second tr td:nth-child(4) {
    width: 18%;
  }
  .page__inner .documentation__wrap .more__documents {
    width: 99.2%;
    margin: 0 auto;
  }
  .page__inner .auctioneer__page .documentation__table tr th:nth-child(2) {
    width: 13%;
  }
  .page__inner .auctioneer__page .documentation__table--second tr td:nth-child(2) {
    width: 13%;
  }
  .page__inner .auctioneer__page .documentation__table--second tr td:nth-child(3) {
    text-align: left;
  }
  .page__inner .auctioneer__page .documentation__table--second tr td:nth-child(3) a {
    padding: 23px 0 23px 20px;
  }
  .page__inner .single__product .container {
    width: 970px;
  }
  .page__inner .single__product .sidebar {
    margin-right: 50px;
  }
}

@media all and (max-width: 990px) {
  section:after {
    display: none;
  }
  .header--nav {
    display: none;
  }
  header .language {
    display: none;
  }
  header nav ul li {
    margin-right: 0;
  }
  header nav ul li .sub-menu {
    margin-top: 10px;
    padding: 0;
    left: 0;
  }
  header nav ul li .sub-menu li a:before {
    display: none;
  }
  header nav ul .menu-item-has-children:before {
    display: none;
  }
  header nav ul .menu-item-has-children a {
    position: relative;
  }
  header nav ul .menu-item-has-children a:before {
    display: block;
    content: "";
    width: 0;
    height: 0;
    border-left: 3.5px solid transparent;
    border-right: 3.5px solid transparent;
    border-top: 4px solid #fff;
    position: absolute;
    top: 7px;
    right: -22px;
  }
  header .mobile__bar {
    display: block;
    width: 150px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background: #8e94c1;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s all ease-out;
  }
  header .mobile__bar.close__menu--wrap {
    background: #fff;
  }
  header .mobile__bar .menu__button {
    height: 3px;
    width: 30px;
    background-color: #fff;
    position: relative;
    transition: .5s all ease-out;
  }
  header .mobile__bar .menu__button:before {
    display: block;
    content: '';
    width: 100%;
    height: 3.5px;
    position: absolute;
    left: 0;
    top: -9px;
    background-color: #fff;
    transition: .5s all ease-out;
  }
  header .mobile__bar .menu__button:after {
    display: block;
    content: '';
    width: 100%;
    height: 3.5px;
    margin-top: 6px;
    position: absolute;
    left: 0;
    bottom: -9px;
    background-color: #fff;
    transition: .5s all ease-out;
  }
  header .mobile__bar .menu__button.close__menu {
    background: transparent;
  }
  header .mobile__bar .menu__button.close__menu:before {
    transform: rotate(45deg);
    top: -1px;
    background-color: #322c74;
  }
  header .mobile__bar .menu__button.close__menu:after {
    transform: rotate(-45deg);
    bottom: 0;
    background-color: #322c74;
  }
  header .mobile__menu {
    position: absolute;
    left: 0;
    top: 90px;
    width: 100%;
    background: #322c74;
    padding: 30px 0 40px 30px;
  }
  header .mobile__menu .header--nav, header .mobile__menu .language {
    display: block;
  }
  header .mobile__menu .header--nav {
    padding-top: 0;
  }
  header .mobile__menu .header--nav .headnav {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  header .mobile__menu .header--nav .headnav li {
    margin-right: 0;
    margin-bottom: 35px;
  }
  header .mobile__menu .header--nav .headnav li:last-child {
    margin-bottom: 0;
  }
  header .mobile__menu .header--nav .headnav li:after {
    margin-top: 15px;
  }
  header .mobile__menu .header--nav .headnav li .sub-menu {
    position: relative;
    opacity: 1;
    visibility: visible;
    padding-top: 35px;
  }
  header .mobile__menu .language {
    position: relative;
    background: transparent;
    padding: 0;
    width: 100%;
    height: auto;
    max-width: 130px;
    margin: 0 auto;
  }
  header .mobile__menu .language .hidden__lang {
    top: -150px;
    width: 150px;
  }
  header .mobile__menu .language .hidden__lang li a {
    padding: 25px 0;
    text-align: center;
  }
  .first__block {
    margin-top: 110px;
    height: calc(100vh - 110px);
  }
  .first__block .slider__item {
    height: calc(100vh - 110px);
  }
  .second__block {
    flex-direction: column-reverse;
  }
  .second__block .left__side--image {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
    padding-top: 35px;
    background: linear-gradient(to bottom, #fdfdfd, #fff);
  }
  .second__block .right__side--content {
    width: 100%;
    max-width: 100%;
    padding-right: 0;
    margin-bottom: 100px;
    padding: 0 30px;
  }
  .third__block {
    padding-top: 70px;
  }
  .third__block:before {
    top: 0;
    height: 100%;
  }
  .fourth__block {
    padding-top: 100px;
  }
  .fourth__block:before {
    width: 1115%;
    top: -150px;
  }
  .fourth__block .block__title {
    margin-bottom: 100px;
  }
  .fourth__block .advantages__wrap {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .fourth__block .advantage__item {
    width: 48%;
    max-width: 50%;
    margin-bottom: 30px;
    margin-right: 0;
  }
  .fifth__block {
    padding-top: 110px;
  }
  .fifth__block .block__title {
    margin-bottom: 70px;
  }
  .fifth__block .news__wrap {
    flex-wrap: wrap;
  }
  .fifth__block .news__item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 40px;
  }
  .fifth__block .news__item .thumb__wrap {
    height: auto;
  }
  footer .footer__container:after {
    top: 55%;
  }
  footer .footer__content--wrap {
    flex-wrap: wrap;
  }
  footer .footer__logo {
    width: 100%;
    margin-right: 0;
    text-align: center;
    margin-bottom: 50px;
  }
  footer .footer__logo img {
    width: 200px;
  }
  footer .footer__column {
    margin-right: 0;
    width: 50%;
  }
  .page__inner {
    margin-top: 105px;
  }
  .page__inner .inner__banner ul.breadcrumbs {
    margin-bottom: 15px;
  }
  .page__inner .inner__banner ul.breadcrumbs a {
    font-size: 14px;
  }
  .page__inner .inner__banner h1 {
    font-size: 48px;
  }
  .page__inner .content__block .left__side {
    padding: 30px;
  }
  .page__inner .content__block .left__side h4 {
    font-size: 32px;
    margin-bottom: 25px;
  }
  .page__inner .content__block .left__side .undertitle {
    margin-bottom: 25px;
  }
  .page__inner .content__block .left__side p {
    margin-bottom: 25px;
  }
  .page__inner .content__block .left__side .more__button {
    width: 180px;
    padding: 15px 0;
    margin-bottom: 25px;
    opacity: 1;
  }
  .page__inner .bottom__links--wrap {
    flex-wrap: wrap;
  }
  .page__inner .bottom__links--wrap .bottom__item {
    max-width: 49%;
    width: 49%;
    height: 300px;
    margin-bottom: 50px;
  }
  .page__inner .documentation__wrap .block__line {
    margin-bottom: 50px;
  }
  .page__inner .documentation__wrap .documentation__table tr th:before {
    right: 15px;
  }
  .page__inner .documentation__wrap .documentation__table tr th:first-child {
    width: 32%;
    padding-left: 20px;
  }
  .page__inner .documentation__wrap .documentation__table tr th:nth-child(2) {
    width: 30%;
    padding-left: 20px;
  }
  .page__inner .documentation__wrap .documentation__table tr th:nth-child(3) {
    width: 15%;
  }
  .page__inner .documentation__wrap .documentation__table tr th:nth-child(3):before {
    right: 15px;
  }
  .page__inner .documentation__wrap .documentation__table tr th:nth-child(4) {
    width: 23%;
  }
  .page__inner .documentation__wrap .documentation__table tr:nth-child(2) td {
    width: 98.9%;
  }
  .page__inner .documentation__wrap .documentation__table--second tr td:first-child {
    width: 32%;
    padding-left: 20px;
  }
  .page__inner .documentation__wrap .documentation__table--second tr td:nth-child(2) {
    width: 30%;
    padding-left: 20px;
  }
  .page__inner .documentation__wrap .documentation__table--second tr td:nth-child(3) {
    width: 15%;
  }
  .page__inner .documentation__wrap .documentation__table--second tr td:nth-child(4) {
    width: 23%;
  }
  .page__inner .documentation__wrap .documentation__table--second tr td:nth-child(4) a {
    padding: 23px 0 23px 25px;
  }
  .page__inner .documentation__wrap .documentation__table--second tr td span {
    display: none;
  }
  .page__inner .auctioneer__page .block__line {
    margin-bottom: 50px;
  }
  .page__inner .auctioneer__page .documentation__table tr th:first-child {
    width: 45.4%;
    padding-left: 30px;
  }
  .page__inner .auctioneer__page .documentation__table tr th:nth-child(2) {
    width: 15%;
  }
  .page__inner .auctioneer__page .documentation__table tr th:nth-child(3) {
    width: 23.4%;
  }
  .page__inner .auctioneer__page .documentation__table tr:nth-child(2) td {
    width: 98.9%;
  }
  .page__inner .auctioneer__page .documentation__table--second tr td {
    padding: 23px 0 23px 30px;
  }
  .page__inner .auctioneer__page .documentation__table--second tr td:first-child {
    width: 45.4%;
    padding-left: 30px;
  }
  .page__inner .auctioneer__page .documentation__table--second tr td:nth-child(2) {
    padding: 15px 0;
    width: 15%;
    text-align: center;
  }
  .page__inner .auctioneer__page .documentation__table--second tr td:nth-child(3) {
    width: 21.6%;
  }
  .page__inner .auctioneer__page .documentation__table--second tr td:nth-child(3) a {
    padding: 23px 0 23px 30px;
  }
  .page__inner .auctioneer__page .documentation__table--second tr td:nth-child(3) a:before {
    top: 25px;
    right: 25px;
  }
  .page__inner .auctioneer__page .documentation__table--second tr td span {
    display: none;
  }
  .page__inner .documentation__wrap {
    padding: 100px 0;
  }
  .page__inner .documentation__wrap .search__wrap {
    align-items: center;
    margin-bottom: 75px;
  }
  .page__inner .documentation__wrap .search__wrap form input {
    width: 200px;
  }
  .page__inner .documentation__wrap .search__wrap form .search-submit {
    width: 150px;
  }
  .page__inner .contacts__inner {
    padding: 100px 0;
  }
  .page__inner .contacts__inner .undertitile {
    margin-bottom: 75px;
  }
  .page__inner .contacts__inner #map {
    margin-bottom: 100px;
  }
  .page__inner .contacts__inner .contacts__info--wrap .col-md-12 {
    flex-direction: column;
  }
  .page__inner .contacts__inner .contacts__info--wrap .left {
    margin-bottom: 50px;
    margin-right: 0;
  }
  .page__inner .contacts__inner .contacts__info--wrap form .wpcf7-form-control-wrap {
    width: 48.7%;
    max-width: 320px;
  }
  .page__inner .news__inner .news__wrap .wp-pagenavi {
    margin-bottom: 0;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap .point__logo {
    width: 200px;
    height: 50px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap .point__logo img {
    max-width: 100px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.ternopol {
    top: 160px;
    left: 130px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.ternopol:hover {
    top: 120px;
    left: 110px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.vinnitsya {
    top: 200px;
    left: 240px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.vinnitsya:hover {
    top: 160px;
    left: 222px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.kiev {
    top: 140px;
    left: 295px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.kiev:hover {
    top: 100px;
    left: 275px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.nikolaev {
    bottom: 190px;
    left: 360px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.nikolaev:hover {
    bottom: 230px;
    left: 342px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.poltava {
    top: 165px;
    right: 240px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.poltava:hover {
    right: 257px;
    top: 125px;
  }
  .page__inner .problems__inner {
    padding-top: 80px;
  }
  .page__inner .problems__inner .undertitile {
    margin-bottom: 100px;
  }
  .page__inner .problems__inner .problem__block {
    flex-direction: column;
    padding: 0;
    margin-bottom: 100px;
  }
  .page__inner .problems__inner .problem__block img {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    max-height: 300px;
  }
  .page__inner .problems__inner .problem__block .description__wrap {
    padding: 30px;
  }
  .page__inner .products__wrap {
    padding: 100px 0 150px;
  }
  .page__inner .products__wrap .product__cat .bottom__bg {
    height: 200px;
  }
  .page__inner .products__wrap .product__cat .image__wrap {
    height: 200px;
  }
  .page__inner .products__wrap .product__cat .description__block a {
    font-size: 14px;
  }
  .page__inner .products__wrap .product__cat img {
    max-height: 200px;
  }
  .page__inner .products__all--wrap .product__cat {
    height: 200px;
    max-width: 210px;
    margin-right: 10px;
  }
  .page__inner .products__all--wrap .product__cat h4 a {
    display: block;
    width: 100%;
    font-size: 16px;
    text-align: center;
  }
  .page__inner .team__inner .team__member {
    padding: 50px 50px 50px 250px;
    background-size: 200px;
    margin-bottom: 100px;
  }
  .page__inner .team__inner .team__member img {
    width: 250px;
    height: auto;
    top: -20px;
  }
  .page__inner .team__inner .team__member .position {
    margin-bottom: 20px;
  }
  .page__inner .team__inner .team__member .description {
    max-height: 120px;
    overflow: hidden;
    margin-bottom: 30px;
  }
  .page__inner .team__inner .all__members:before {
    top: 350px;
  }
  .page__inner .products__cat--wrap {
    padding: 80px 0;
  }
  .page__inner .products__cat--wrap .col-md-3 {
    margin-bottom: 50px;
  }
  .page__inner .products__cat--wrap .product__cat {
    width: 48.4%;
  }
  .page__inner .products__cat--wrap .product__cat .bottom__bg {
    height: 110px;
  }
  .page__inner .resources__inner {
    padding: 100px 0;
  }
  .page__inner .resources__inner .undertitle {
    margin-bottom: 50px;
  }
  .page__inner .resources__inner .resources__tabs--wrap {
    margin-bottom: 50px;
  }
  .page__inner .resources__inner .resources__tabs--wrap .res__tab {
    height: 250px;
  }
  .page__inner .resources__inner .resources__tabs--wrap .res__tab h4 {
    padding: 20px 5px;
    font-size: 16px;
  }
  .page__inner .resources__inner .resources__tab--item .documentation__table tr th:nth-child(2) {
    width: 19.8%;
  }
  .page__inner .resources__inner .resources__tab--item .documentation__table tr:nth-child(2) td {
    width: 98.9%;
  }
  .page__inner .resources__inner .resources__tab--item .documentation__table--second tr td {
    padding: 23px 0 23px 30px;
  }
  .page__inner .resources__inner .resources__tab--item .documentation__table--second tr td:nth-child(2) {
    padding: 15px 0;
    width: 19.8%;
    text-align: center;
  }
  .page__inner .resources__inner .resources__tab--item .documentation__table--second tr td span {
    display: none;
  }
  .page__inner .resources__inner .resources__tab--item .left__image {
    width: 100%;
    display: block;
  }
  .page__inner .resources__inner .resources__tab--item .left__image img {
    max-height: 350px;
  }
  .page__inner .resources__inner .resources__tab--item .right__content {
    width: 100%;
  }
  .page__inner .single__inner {
    padding: 100px 0;
  }
  .page__inner .single__inner .author__block {
    margin-bottom: 50px;
  }
  .page__inner .single__inner .post__content {
    margin-bottom: 70px;
  }
  .page__inner .single__inner .post__content .post__image {
    margin-bottom: 50px;
  }
  .page__inner .single__inner .post__content h6 {
    margin-bottom: 30px;
  }
  .page__inner .single__inner .post__content p {
    margin-bottom: 30px;
  }
  .page__inner .single__inner .post__content img.alignleft, .page__inner .single__inner .post__content img.alignright {
    display: block;
    float: none;
    margin: 0 auto;
    margin-bottom: 20px;
    width: auto;
    height: auto;
    max-width: 100%;
  }
  .page__inner .single__inner .post__content blockquote {
    padding: 50px 100px;
    font-size: 20px;
    margin-bottom: 50px;
  }
  .page__inner .single__inner .post__content blockquote:before {
    font-size: 100px;
    top: 65px;
    left: 35px;
  }
  .page__inner .single__inner .post__content blockquote p {
    font-size: 20px;
  }
  .page__inner .single__inner .prod__navigation--wrap .prev {
    padding-left: 60px;
  }
  .page__inner .single__inner .prod__navigation--wrap .prev:before {
    left: 30px;
    top: 50px;
  }
  .page__inner .single__inner .prod__navigation--wrap .next {
    padding-right: 60px;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }
  .page__inner .single__inner .prod__navigation--wrap .next:before {
    right: 30px;
    top: 50px;
  }
  .page__inner .single__problems--inner {
    padding: 100px 0;
  }
  .page__inner .single__problems--inner .content__wrap {
    flex-direction: column;
  }
  .page__inner .single__problems--inner .content__wrap .left__image {
    width: 100%;
    margin-right: 0;
    margin-bottom: 50px;
  }
  .page__inner .single__problems--inner .content__wrap .left__image img {
    max-height: 350px;
  }
  .page__inner .single__problems--inner .content__wrap .right__content {
    width: 100%;
  }
  .page__inner .single__problems--inner .content__wrap .right__content h4 {
    margin-bottom: 30px;
  }
  .page__inner .single__problems--inner .content__wrap .right__content p {
    margin-bottom: 30px;
  }
  .page__inner .single__problems--inner .prod__navigation--wrap .prev {
    padding-left: 60px;
  }
  .page__inner .single__problems--inner .prod__navigation--wrap .prev:before {
    left: 30px;
    top: 50px;
  }
  .page__inner .single__problems--inner .prod__navigation--wrap .next {
    padding-right: 60px;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }
  .page__inner .single__problems--inner .prod__navigation--wrap .next:before {
    right: 30px;
    top: 50px;
  }
  .page__inner .single__product {
    padding: 50px 0 0;
  }
  .page__inner .single__product .container {
    width: 678px;
  }
  .page__inner .single__product .product__wrap {
    flex-direction: column;
    margin-bottom: 60px;
  }
  .page__inner .single__product .sidebar {
    max-width: 100%;
    width: 100%;
  }
  .page__inner .single__product .sidebar #my-slider {
    margin-bottom: 75px;
  }
  .page__inner .single__product .sidebar .top__image img {
    max-height: 480px;
  }
  .page__inner .single__product .sidebar .top__image .bottom__bg {
    height: 265px;
  }
  .page__inner .single__product .sidebar .sp-thumbnails-container {
    max-width: 400px;
    margin: 0 auto;
    margin-top: 20px;
  }
  .page__inner .single__product .sidebar .small__images {
    max-width: 450px;
    margin: 0 auto;
    margin-bottom: 50px;
  }
  .page__inner .single__product .sidebar .documents {
    max-width: 400px;
    margin: 0 auto;
    margin-bottom: 60px;
  }
  .page__inner .single__product .sidebar .documents h4 {
    text-align: center;
    margin-bottom: 25px;
  }
  .page__inner .single__product .sidebar .documents ul li a {
    padding: 20px 30px;
  }
  .page__inner .single__product .main__content {
    max-width: 100%;
    width: 100%;
  }
  .page__inner .single__product .main__content .content__title {
    margin-bottom: 25px;
  }
  .page__inner .single__product .main__content .single__char div h6 {
    width: 56%;
  }
  .page__inner .single__product .main__content .single__char div p {
    width: 44%;
  }
  .page__inner .single__product .single__product--bottom {
    margin-bottom: 80px;
  }
  .page__inner .single__product .single__product--bottom img.alignright, .page__inner .single__product .single__product--bottom img.alignleft {
    max-width: 200px;
  }
  .page__inner .single__product .single__product--bottom img.aligncenter {
    display: block;
    max-width: 495px;
    margin: 0 auto;
    margin-bottom: 65px;
  }
  .page__inner .single__product .single__product--bottom .sprec__tabs__items {
    margin-bottom: 15px;
  }
  .page__inner .single__product .single__product--bottom .sprec__tabs__items li {
    width: 33.3%;
    font-size: 14px;
  }
  .page__inner .single__product .single__product--bottom .spec__tab--content--wrap .spec__tab--content .accordeon__top {
    font-size: 14px;
    height: 74px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
  }
  .page__inner .single__product .single__product--bottom .spec__tab--content--wrap .spec__tab--content .accordeon__top:before {
    top: 30px;
  }
  .page__inner .single__product .single__product--bottom .spec__tab--content--wrap .spec__tab--content:nth-child(2) .accordeon__content .content__row .left, .page__inner .single__product .single__product--bottom .spec__tab--content--wrap .spec__tab--content:nth-child(2) .accordeon__content .content__row .right {
    padding: 20px 15px 19px 20px;
  }
  .page__inner .single__product .single__product--bottom .spec__tab--content--wrap .spec__tab--content:nth-child(3) .accordeon__content {
    padding: 30px 20px;
  }
  .page__inner .single__product .single__product--bottom .spec__tab--content--wrap .accordeon__content {
    padding: 30px 30px 30px 50px;
  }
  .page__inner .single__product .single__product--bottom .spec__tab--content--wrap .accordeon__content ul {
    padding-left: 20px;
  }
  .page__inner .single__product .prod__navigation--wrap .prev {
    padding-left: 60px;
  }
  .page__inner .single__product .prod__navigation--wrap .prev:before {
    left: 30px;
    top: 50px;
  }
  .page__inner .single__product .prod__navigation--wrap .next {
    padding-right: 60px;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }
  .page__inner .single__product .prod__navigation--wrap .next:before {
    right: 30px;
    top: 50px;
  }
  .prod__navigation--wrap .next {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex !important;
    align-items: center !important;
    padding: 0 60px 0 15px !important;
  }
}

@media all and (max-width: 500px) {
  .block__title {
    font-size: 36px;
  }
  header {
    padding: 25px 0 30px 0;
  }
  header .header--logo {
    padding-left: 15px;
  }
  header .mobile__bar {
    width: 105px;
  }
  header .mobile__bar .menu__button:before, header .mobile__bar .menu__button:after {
    height: 3px;
  }
  header .mobile__bar .menu__button.close__menu:before {
    top: 0;
  }
  header .mobile__menu {
    top: 105px;
    height: auto;
    padding: 30px 0 120px 30px;
  }
  header .mobile__menu .language {
    position: absolute;
    top: unset;
    bottom: 30px;
    left: 0;
  }
  header .mobile__menu .language .hidden__lang {
    z-index: 99;
    top: -140px;
  }
  header .mobile__menu .header--nav .headnav li {
    line-height: .2;
  }
  .first__block {
    height: calc(100vh - 92px);
  }
  .first__block .slider__item {
    height: calc(100vh - 92px);
  }
  .first__block .slider__item .slider__text--wrap {
    left: 0;
    padding: 0 30px;
  }
  .first__block .slider__item .slider__text--wrap .mark {
    margin-bottom: 15px;
  }
  .first__block .slider__item .slider__text--wrap h4 {
    font-size: 47px;
    margin-bottom: 20px;
  }
  .first__block .slider__item .slider__text--wrap .button__wrap .video__open {
    display: none;
  }
  .first__block .to__second {
    display: none;
  }
  .first__block #banner__owl .owl-nav .owl-prev {
    bottom: 25px;
    top: unset;
    left: 30px;
  }
  .first__block #banner__owl .owl-nav .owl-next {
    top: unset;
    bottom: 25px;
    left: 82px;
  }
  .second__block .right__side--content .content__titile {
    font-size: 36px;
  }
  .third__block:before {
    left: -30px;
    width: 120%;
  }
  .third__block .block__title {
    margin-bottom: 75px;
  }
  .third__block #products__owl .owl-stage {
    margin-bottom: 65px;
  }
  .third__block #products__owl .owl-nav {
    width: 105px;
    height: 50px;
    position: relative;
    margin: 0 auto;
  }
  .third__block #products__owl .owl-nav .owl-prev {
    left: 0;
    top: unset;
    bottom: 0;
  }
  .third__block #products__owl .owl-nav .owl-next {
    right: 0;
    top: unset;
    bottom: 0;
  }
  .fourth__block {
    padding-top: 0;
  }
  .fourth__block:before {
    width: 120%;
    height: 90%;
  }
  .fourth__block .block__title {
    margin-bottom: 75px;
  }
  .fourth__block .advantage__item {
    width: 100%;
    max-width: 100%;
    text-align: center;
  }
  .fourth__block .advantage__item:before {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .fourth__block .advantage__item .number {
    margin-bottom: 20px;
  }
  .fourth__block .advantage__item .number:after {
    margin: 0 auto;
    margin-top: 10px;
  }
  .fifth__block {
    padding-top: 0;
    padding-bottom: 50px;
  }
  .fifth__block .col-md-12 {
    padding: 0;
  }
  .fifth__block .news__item {
    margin-bottom: 30px;
  }
  .fifth__block .news__item .description__wrap .post__text--wrap p {
    margin-bottom: 20px;
  }
  footer .footer__container:after {
    top: 60%;
    right: -250px;
  }
  footer .footer__logo {
    margin-bottom: 90px;
  }
  footer .footer__logo img {
    max-width: 150px;
  }
  footer .footer__column {
    width: 100%;
    margin-bottom: 65px;
    padding-left: 45px;
  }
  footer .footer__column:last-child {
    margin-bottom: 30px;
  }
  footer .footer__bottom--wrap {
    flex-direction: column;
    align-items: center;
  }
  footer .footer__bottom--wrap .copyright {
    margin-bottom: 15px;
    text-align: center;
  }
  footer .footer__bottom--wrap .copyright .auctioneer__link {
    display: block;
    margin-left: 0;
    margin-top: 15px;
  }
  .error404 .error__inner .page__wrap h1 {
    font-size: 140px;
  }
  .error404 .error__inner .page__wrap p {
    font-size: 20px;
  }
  .page__inner {
    margin-top: 135px;
  }
  .page__inner .inner__banner {
    height: 250px;
    padding-left: 40px;
  }
  .page__inner .inner__banner h1 {
    font-size: 40px;
    line-height: 1.01;
  }
  .page__inner .content__block {
    flex-direction: column-reverse;
  }
  .page__inner .content__block .left__side {
    width: 100%;
    max-width: 100%;
  }
  .page__inner .content__block .right__side {
    padding-top: 50px;
    width: 100%;
    max-width: 100%;
  }
  .page__inner .content__block:nth-child(even) {
    flex-direction: column-reverse;
  }
  .page__inner .content__block:nth-child(even) .left__side {
    padding: 30px;
  }
  .page__inner .content__block:nth-child(even) .right__side {
    padding-top: 50px;
    padding-bottom: 0;
  }
  .page__inner .bottom__links--wrap {
    padding: 50px 0;
  }
  .page__inner .bottom__links--wrap .bottom__item {
    max-width: 100%;
    width: 100%;
    height: 250px;
  }
  .page__inner .documentation__wrap .block__line {
    margin-bottom: 50px;
  }
  .page__inner .documentation__wrap .documentation__table tr th {
    padding-left: 0 !important;
    text-align: center;
  }
  .page__inner .documentation__wrap .documentation__table tr th a {
    padding: 18px 5px !important;
    text-align: center;
    font-size: 12px !important;
  }
  .page__inner .documentation__wrap .documentation__table tr th:before {
    display: none !important;
  }
  .page__inner .documentation__wrap .documentation__table tr th:first-child {
    width: 33%;
  }
  .page__inner .documentation__wrap .documentation__table tr th:nth-child(2) {
    width: 31%;
  }
  .page__inner .documentation__wrap .documentation__table tr th:nth-child(3) {
    width: 16%;
  }
  .page__inner .documentation__wrap .documentation__table tr th:nth-child(4) {
    width: 20%;
  }
  .page__inner .documentation__wrap .documentation__table tr:nth-child(2) td {
    width: 97.9%;
  }
  .page__inner .documentation__wrap .documentation__table--second tr td {
    font-size: 12px !important;
    text-align: center;
  }
  .page__inner .documentation__wrap .documentation__table--second tr td:first-child {
    width: 33%;
  }
  .page__inner .documentation__wrap .documentation__table--second tr td:nth-child(2) {
    width: 31%;
  }
  .page__inner .documentation__wrap .documentation__table--second tr td:nth-child(3) {
    width: 16%;
  }
  .page__inner .documentation__wrap .documentation__table--second tr td:nth-child(4) {
    width: 20%;
  }
  .page__inner .documentation__wrap .documentation__table--second tr td:nth-child(4):before {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
  .page__inner .documentation__wrap .documentation__table--second tr td:nth-child(4) a {
    display: block;
    text-indent: -10000px;
  }
  .page__inner .documentation__wrap .documentation__table--second tr td:nth-child(4) a:before {
    right: 17px;
  }
  .page__inner .documentation__wrap .documentation__table--second tr td:nth-child(4):hover a {
    text-indent: -10000px;
  }
  .page__inner .documentation__wrap .documentation__table--second tr td span {
    display: none;
  }
  .page__inner .documentation__wrap .more__documents {
    width: 98.2%;
  }
  .page__inner .auctioneer__page .block__line {
    margin-bottom: 50px;
  }
  .page__inner .auctioneer__page .documentation__table tr:nth-child(2) td {
    top: 55px;
  }
  .page__inner .auctioneer__page .documentation__table tr th a {
    font-size: 10px;
    padding: 23px 0 23px 10px;
  }
  .page__inner .auctioneer__page .documentation__table tr th:before {
    right: 10px;
    top: 27px;
  }
  .page__inner .auctioneer__page .documentation__table tr th:first-child {
    width: 44%;
  }
  .page__inner .auctioneer__page .documentation__table tr th:nth-child(2) {
    width: 20%;
  }
  .page__inner .auctioneer__page .documentation__table tr th:nth-child(3) {
    width: 26%;
  }
  .page__inner .auctioneer__page .documentation__table tr th:nth-child(3):before {
    width: 15px;
    height: 15px;
    right: 10px;
    background-size: contain;
  }
  .page__inner .auctioneer__page .documentation__table tr:nth-child(2) td {
    width: 98%;
  }
  .page__inner .auctioneer__page .documentation__table--second tr td {
    padding: 23px 0 23px 10px;
    font-size: 10px;
  }
  .page__inner .auctioneer__page .documentation__table--second tr td:first-child {
    width: 44%;
  }
  .page__inner .auctioneer__page .documentation__table--second tr td:nth-child(2) {
    width: 20%;
  }
  .page__inner .auctioneer__page .documentation__table--second tr td:nth-child(3) {
    width: 26%;
  }
  .page__inner .auctioneer__page .documentation__table--second tr td:nth-child(3) a {
    padding: 23px 0 23px 5px;
  }
  .page__inner .auctioneer__page .documentation__table--second tr td:nth-child(3) a:before {
    width: 15px;
    height: 15px;
    right: 5px;
    top: 0;
    background-size: contain;
  }
  .page__inner .documentation__wrap {
    padding: 80px 0;
  }
  .page__inner .documentation__wrap .search__wrap {
    margin-bottom: 50px;
    flex-direction: column;
  }
  .page__inner .documentation__wrap .search__wrap form {
    width: 100%;
    margin-bottom: 30px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
  }
  .page__inner .documentation__wrap .search__wrap form input {
    width: 65%;
    padding: 16px 0 15px 20px;
  }
  .page__inner .documentation__wrap .search__wrap form .search-submit {
    width: 35%;
  }
  .page__inner .contacts__inner {
    padding: 80px 0;
  }
  .page__inner .contacts__inner .undertitile {
    margin-bottom: 50px;
  }
  .page__inner .contacts__inner #map {
    margin-bottom: 80px;
    height: 450px;
  }
  .page__inner .contacts__inner .contacts__info--wrap .left .contacts__cards--wrap .card {
    width: 100%;
    height: 175px;
  }
  .page__inner .contacts__inner .contacts__info--wrap .left .contacts__cards--wrap .card.phones .desktop__phone {
    display: none;
  }
  .page__inner .contacts__inner .contacts__info--wrap .left .contacts__cards--wrap .card.phones .mobile__phone {
    display: block;
  }
  .page__inner .contacts__inner .contacts__info--wrap form .wpcf7-form-control-wrap {
    width: 100%;
    max-width: 400px;
  }
  .page__inner .contacts__inner .contacts__info--wrap form .send__button {
    display: block;
    margin: 0 auto;
  }
  .page__inner .news__inner {
    padding: 100px 0;
  }
  .page__inner .news__inner .col-md-12 {
    padding: 0 30px;
  }
  .page__inner .news__inner .news__wrap .news__item .thumbnail__wrap {
    margin-bottom: 100px;
  }
  .page__inner .news__inner .news__wrap .news__item .description__wrap {
    position: relative;
  }
  .page__inner .news__inner .news__wrap .news__item .description__wrap .left__author {
    position: absolute;
    right: 0;
    top: -95px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    padding-bottom: 0;
    margin-right: 0;
    background-color: #6cb7df;
    border-radius: 50px 0 0 50px;
    width: 100%;
  }
  .page__inner .news__inner .news__wrap .news__item .description__wrap .left__author:before {
    display: none;
  }
  .page__inner .news__inner .news__wrap .news__item .description__wrap .left__author img {
    width: 90px;
    height: 90px;
    margin: 0;
  }
  .page__inner .news__inner .news__wrap .news__item .description__wrap .left__author .author__name {
    padding: 10px 15px;
    background-color: #6cb7df;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .page__inner .news__inner .news__wrap .news__item .description__wrap .right__description {
    width: 100%;
    margin: 50px 0;
  }
  .page__inner .news__inner .news__wrap .news__item .description__wrap .right__description h4 {
    font-size: 28px;
    margin-bottom: 25px;
  }
  .page__inner .news__inner .news__wrap .news__item .description__wrap .right__description .undertitle {
    margin-bottom: 25px;
  }
  .page__inner .news__inner .news__wrap .news__item .description__wrap .right__description .excerpt {
    margin-bottom: 25px;
  }
  .page__inner .faq__inner {
    padding: 80px 0;
  }
  .page__inner .faq__inner .undertitle {
    margin-bottom: 50px;
  }
  .page__inner .partners__inner .map__inner .map__point {
    width: 25px;
    height: 25px;
  }
  .page__inner .partners__inner .map__inner .map__point .point__inner {
    width: 14px;
    height: 14px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap:hover .map__point {
    width: 50px;
    height: 50px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap:hover .map__point .point__inner {
    width: 30px;
    height: 30px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap:hover .map__point .point__inner:before {
    width: 20px;
    height: 20px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap .point__logo {
    width: 150px;
    height: 50px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap .point__logo img {
    max-width: 50px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.ternopol {
    top: 70px;
    left: 65px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.ternopol:hover {
    top: 45px;
    left: 55px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.vinnitsya {
    top: 97px;
    left: 120px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.vinnitsya:hover {
    top: 65px;
    left: 108px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.kiev {
    top: 60px;
    left: 150px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.kiev:hover {
    top: 30px;
    left: 137px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.nikolaev {
    bottom: 115px;
    left: 185px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.nikolaev:hover {
    bottom: 143px;
    left: 175px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.poltava {
    top: 75px;
    right: 130px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.poltava:hover {
    right: 140px;
    top: 45px;
  }
  .page__inner .problems__inner .problem__block h4 {
    font-size: 24px;
    margin-bottom: 25px;
  }
  .page__inner .problems__inner .problem__block .position {
    margin-bottom: 25px;
  }
  .page__inner .problems__inner .problem__block .description {
    margin-bottom: 25px;
  }
  .page__inner .problems__inner .problem__block .description__wrap {
    background: #4d4f8c url(../img/logo-p.svg) 170px 100% no-repeat;
  }
  .page__inner .products__wrap {
    padding: 100px 0;
  }
  .page__inner .products__wrap .product__cat {
    width: 100%;
    margin-bottom: 100px;
  }
  .page__inner .products__wrap .product__cat .bottom__bg {
    height: 200px;
  }
  .page__inner .products__wrap .product__cat .image__wrap {
    height: 200px;
  }
  .page__inner .products__wrap .product__cat .description__block a {
    font-size: 16px;
  }
  .page__inner .products__wrap .product__cat img {
    max-height: 200px;
  }
  .page__inner .products__all--wrap .product__cat {
    height: 320px;
    max-width: 100%;
    width: 100%;
    margin-bottom: 30px;
    margin-right: 10px;
  }
  .page__inner .products__all--wrap .product__cat .image__wrap {
    height: 100%;
  }
  .page__inner .products__all--wrap .product__cat h4 a {
    display: block;
    width: 100%;
    font-size: 16px;
    text-align: center;
  }
  .page__inner .team__inner {
    padding-top: 80px;
  }
  .page__inner .team__inner .undertitile {
    margin-bottom: 50px;
  }
  .page__inner .team__inner .team__cards--wrap {
    margin-bottom: 100px;
  }
  .page__inner .team__inner .team__member {
    flex-direction: column;
    padding: 0;
    height: auto;
  }
  .page__inner .team__inner .team__member img {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
  }
  .page__inner .team__inner .team__member .description__wrap {
    padding: 20px;
  }
  .page__inner .team__inner .team__member .description {
    max-height: 100%;
  }
  .page__inner .team__inner .team__member h4 {
    font-size: 24px;
  }
  .page__inner .team__inner .all__members {
    padding-bottom: 100px;
  }
  .page__inner .team__inner .all__members:before {
    top: 300px;
    left: -140px;
  }
  .page__inner .team__inner .all__members .undertitile {
    margin-bottom: 50px;
  }
  .page__inner .products__cat--wrap {
    padding: 80px 0;
  }
  .page__inner .products__cat--wrap .col-md-3 {
    margin-bottom: 50px;
  }
  .page__inner .products__cat--wrap .product__cat {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
  }
  .page__inner .products__cat--wrap .product__cat .image__wrap {
    height: 250px;
  }
  .page__inner .products__cat--wrap .product__cat img {
    max-height: 250px;
  }
  .page__inner .products__cat--wrap .product__cat .bottom__bg {
    height: 130px;
  }
  .page__inner .products__cat--wrap .wp-pagenavi {
    margin-bottom: 0;
    padding: 0 15px;
  }
  .page__inner .resources__inner {
    padding: 80px 0;
  }
  .page__inner .resources__inner .resources__tabs--wrap {
    flex-wrap: wrap;
  }
  .page__inner .resources__inner .resources__tabs--wrap .res__tab {
    width: 100%;
    margin-bottom: 30px;
    height: 320px;
  }
  .page__inner .resources__inner .resources__tab--item .documentation__table tr th a {
    font-size: 10px;
    padding: 23px 0 23px 10px;
  }
  .page__inner .resources__inner .resources__tab--item .documentation__table tr th:before {
    right: 10px;
    top: 27px;
  }
  .page__inner .resources__inner .resources__tab--item .documentation__table tr th:first-child {
    padding-left: 15px;
  }
  .page__inner .resources__inner .resources__tab--item .documentation__table tr th:nth-child(3) {
    padding-left: 15px;
  }
  .page__inner .resources__inner .resources__tab--item .documentation__table tr th:nth-child(3):before {
    width: 15px;
    height: 15px;
    right: 10px;
    background-size: contain;
  }
  .page__inner .resources__inner .resources__tab--item .documentation__table tr:nth-child(2) td {
    width: 98%;
  }
  .page__inner .resources__inner .resources__tab--item .documentation__table--second tr td {
    padding: 23px 0 23px 10px;
    font-size: 10px;
  }
  .page__inner .resources__inner .resources__tab--item .documentation__table--second tr td:nth-child(2) {
    padding: 15px 5px;
  }
  .page__inner .resources__inner .resources__tab--item .documentation__table--second tr td:nth-child(3) {
    padding-right: 25px;
  }
  .page__inner .resources__inner .resources__tab--item .documentation__table--second tr td:nth-child(3):before {
    width: 15px;
    height: 15px;
    background-size: contain;
    right: 10px;
  }
  .page__inner .resources__inner .resources__tab--item .left__image img {
    max-height: 300px;
  }
  .page__inner .resources__inner .resources__tab--item .right__content h4 {
    margin-bottom: 20px;
  }
  .page__inner .resources__inner .resources__tab--item .right__content .undertitle {
    margin-bottom: 20px;
  }
  .page__inner .single__inner {
    padding: 50px 0;
  }
  .page__inner .single__inner .author__block {
    width: 80%;
  }
  .page__inner .single__inner .post__content ul li {
    padding-left: 25px;
  }
  .page__inner .single__inner .post__content blockquote {
    padding: 30px 50px;
    font-size: 16px;
  }
  .page__inner .single__inner .post__content blockquote:before {
    font-size: 60px;
    top: 40px;
    left: 10px;
  }
  .page__inner .single__inner .post__content blockquote p {
    font-size: 16px;
  }
  .page__inner .single__inner .prod__navigation--wrap {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .page__inner .single__inner .prod__navigation--wrap .prev:before {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }
  .page__inner .single__inner .prod__navigation--wrap .next:before {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }
  .page__inner .single__problems--inner {
    padding: 50px 0;
  }
  .page__inner .single__problems--inner .content__wrap {
    margin-bottom: 50px;
  }
  .page__inner .single__problems--inner .content__wrap .right__content h4 {
    font-size: 30px;
  }
  .page__inner .single__product {
    padding: 25px 0 0;
  }
  .page__inner .single__product .container {
    width: 100%;
  }
  .page__inner .single__product .sidebar #my-slider {
    margin-bottom: 25px;
  }
  .page__inner .single__product .sidebar .top__image img {
    max-height: 220px;
  }
  .page__inner .single__product .sidebar .top__image .bottom__bg {
    height: 120px;
  }
  .page__inner .single__product .sidebar .sp-thumbnails-container {
    margin-top: 20px;
  }
  .page__inner .single__product .sidebar .small__images {
    max-width: 450px;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .page__inner .single__product .sidebar .specifications {
    margin-bottom: 30px;
  }
  .page__inner .single__product .sidebar .specifications .specifications__accordeon .accordeon__top {
    padding: 22px 0 21px 15px;
    font-size: 12px;
  }
  .page__inner .single__product .sidebar .specifications .specifications__accordeon .accordeon__top:before {
    right: 15px;
  }
  .page__inner .single__product .sidebar .documents {
    margin-bottom: 50px;
  }
  .page__inner .single__product .sidebar .documents h4 {
    margin-bottom: 15px;
  }
  .page__inner .single__product .sidebar .documents ul li a {
    padding: 20px 25px 60px 25px;
    text-align: center;
  }
  .page__inner .single__product .sidebar .documents ul li a:before {
    bottom: 20px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
  }
  .page__inner .single__product .main__content .table__title {
    margin-bottom: 15px;
  }
  .page__inner .single__product .main__content .single__char div {
    padding: 20px;
  }
  .page__inner .single__product .main__content .single__char div h6 {
    width: 50%;
  }
  .page__inner .single__product .main__content .single__char div p {
    width: 50%;
  }
  .page__inner .single__product .single__product--bottom img.alignright, .page__inner .single__product .single__product--bottom img.alignleft {
    float: none;
    display: block;
    max-width: 90%;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .page__inner .single__product .single__product--bottom img.aligncenter {
    max-width: 90%;
    margin-bottom: 30px;
  }
  .page__inner .single__product .single__product--bottom p {
    margin-bottom: 20px;
  }
  .page__inner .single__product .single__product--bottom ul {
    margin-bottom: 20px;
  }
  .page__inner .single__product .single__product--bottom ul li {
    line-height: 1.3;
    margin-bottom: 15px;
  }
  .page__inner .single__product .single__product--bottom .specifications__tabs {
    position: relative;
    margin: 50px 0;
  }
  .page__inner .single__product .single__product--bottom .specifications__tabs .sprec__tabs__items--wrap {
    margin-bottom: 5px;
  }
  .page__inner .single__product .single__product--bottom .specifications__tabs .sprec__tabs__items--wrap .current__tab {
    display: block;
    font-size: 14px;
    color: #fff;
    text-align: center;
    padding: 25px 0;
    text-indent: -20px;
    position: relative;
    background-color: #6cb7df;
  }
  .page__inner .single__product .single__product--bottom .specifications__tabs .sprec__tabs__items--wrap .current__tab:after {
    display: block;
    content: '';
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 7px solid #fff;
    position: absolute;
    right: 40px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }
  .page__inner .single__product .single__product--bottom .sprec__tabs__items {
    margin-bottom: 0;
    background-color: #fff;
    display: none;
    position: absolute;
    width: 100%;
    left: 0;
    top: 70px;
    z-index: 10;
  }
  .page__inner .single__product .single__product--bottom .sprec__tabs__items li {
    width: 100%;
    font-size: 14px;
    background-color: #fff;
  }
  .page__inner .single__product .single__product--bottom .spec__tab--content--wrap .spec__tab--content .accordeon__top {
    font-size: 14px;
    height: 74px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
  }
  .page__inner .single__product .single__product--bottom .spec__tab--content--wrap .spec__tab--content .accordeon__top:before {
    top: 30px;
  }
  .page__inner .single__product .single__product--bottom .spec__tab--content--wrap .spec__tab--content:nth-child(2) .accordeon__content .content__row .left, .page__inner .single__product .single__product--bottom .spec__tab--content--wrap .spec__tab--content:nth-child(2) .accordeon__content .content__row .right {
    padding: 20px 15px 19px 20px;
  }
  .page__inner .single__product .single__product--bottom .spec__tab--content--wrap .spec__tab--content:nth-child(3) .accordeon__content {
    padding: 30px 20px;
  }
  .page__inner .single__product .single__product--bottom .spec__tab--content--wrap .accordeon__content {
    padding: 30px 20px;
  }
  .page__inner .single__product .single__product--bottom .spec__tab--content--wrap .accordeon__content p {
    margin-bottom: 15px;
  }
  .page__inner .single__product .single__product--bottom .spec__tab--content--wrap .accordeon__content ul {
    padding-left: 30px;
    margin-bottom: 20px;
  }
  .page__inner .single__product .single__product--bottom .spec__tab--content--wrap .accordeon__content ul li {
    padding-left: 0;
    line-height: 1.3;
  }
  .page__inner .single__product .prod__navigation--wrap {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .page__inner .single__product .prod__navigation--wrap .prev:before {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }
  .page__inner .single__product .prod__navigation--wrap .next:before {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }
  .page__inner .single__problems--inner .prod__navigation--wrap {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .page__inner .single__problems--inner .prod__navigation--wrap .prev:before {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }
  .page__inner .single__problems--inner .prod__navigation--wrap .next:before {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }
  .prod__navigation--wrap a {
    color: transparent !important;
    max-height: 70px !important;
    background-position: center !important;
    padding: 15px 0 !important;
  }
  .prod__navigation--wrap a span {
    display: none !important;
  }
  .prod__navigation--wrap .prev {
    width: 50% !important;
  }
  .prod__navigation--wrap .next {
    width: 50% !important;
  }
  .wp-pagenavi {
    padding-top: 0 !important;
  }
  .wp-pagenavi .current {
    margin-right: 10px !important;
    height: 40px !important;
  }
  .wp-pagenavi a {
    margin-right: 10px !important;
    height: 43px !important;
  }
  .wp-pagenavi .nextpostslink {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .login__popup--wrap {
    overflow-y: scroll;
  }
  .login__popup--wrap .login__popup--inner .popup {
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
  .login__popup--wrap .login__popup--inner .popup .left__image {
    width: 100%;
    max-height: 150px;
  }
  .login__popup--wrap .login__popup--inner .popup .right__form {
    width: 100%;
    height: calc(100vh - 150px);
    padding: 35px;
  }
  .login__popup--wrap .login__popup--inner .login__popup .right__form {
    height: auto;
    background-color: #fff;
  }
  .login__popup--wrap .login__popup--inner .register__popup .right__form {
    height: auto;
    background-color: #fff;
  }
  .single__page--inner ul.breadcrumbs a {
    font-size: 12px !important;
  }
  .single__page--inner ul.breadcrumbs li span {
    font-size: 12px !important;
  }
  .single__page--inner .inner__banner h1 {
    font-size: 26px !important;
    padding-right: 15px;
  }
}

@media all and (max-width: 400px) {
  .fourth__block:before {
    width: 126%;
    left: -50px;
  }
}

@media all and (max-width: 350px) {
  .page__inner .inner__banner {
    padding-left: 20px;
  }
  .page__inner .inner__banner h1 {
    font-size: 36px;
  }
  .error404 .error__inner .page__wrap h1 {
    font-size: 120px;
  }
  .error404 .error__inner .page__wrap a {
    width: 240px;
    padding: 17px 0;
  }
  .page__inner .documentation__wrap .documentation__table tr:nth-child(2) td {
    width: 97.7%;
  }
  .page__inner .documentation__wrap .documentation__table--second tr td {
    font-size: 10px !important;
  }
  .page__inner .documentation__wrap .more__documents {
    width: 97%;
  }
  .page__inner .auctioneer__page .documentation__table tr th:before {
    display: none !important;
  }
  .page__inner .offenq__accordeon .accordeon__content {
    padding: 30px 25px;
  }
  .page__inner .partners__inner .map__inner .map__point {
    width: 25px;
    height: 25px;
  }
  .page__inner .partners__inner .map__inner .map__point .point__inner {
    width: 14px;
    height: 14px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap:hover .map__point {
    width: 50px;
    height: 50px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap:hover .map__point .point__inner {
    width: 30px;
    height: 30px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap:hover .map__point .point__inner:before {
    width: 20px;
    height: 20px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap .point__logo {
    width: 150px;
    height: 50px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap .point__logo img {
    max-width: 50px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.ternopol {
    top: 60px;
    left: 55px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.ternopol:hover {
    top: 30px;
    left: 40px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.vinnitsya {
    top: 75px;
    left: 100px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.vinnitsya:hover {
    top: 45px;
    left: 90px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.kiev {
    top: 45px;
    left: 125px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.kiev:hover {
    top: 15px;
    left: 110px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.nikolaev {
    bottom: 95px;
    left: 155px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.nikolaev:hover {
    bottom: 125px;
    left: 135px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.poltava {
    top: 60px;
    right: 115px;
  }
  .page__inner .partners__inner .map__inner .map__point--wrap.poltava:hover {
    right: 130px;
    top: 25px;
  }
  .page__inner .resources__inner .resources__tabs--wrap .res__tab {
    font-size: 11px;
  }
  .page__inner .resources__inner .resources__tab--item .documentation__table tr th:before {
    right: 5px;
  }
  .page__inner .single__product .main__content .sprec__tabs__items li {
    font-size: 12px;
  }
  .page__inner .single__product .main__content .spec__tab--content--wrap .accordeon__content h6 {
    font-size: 16px;
  }
}

/*# sourceMappingURL=maps/media.css.map */
