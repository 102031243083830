@media all and (min-width: 2000px) {
	.first__block {
		.slider__item {
			.slider__text--wrap {
				left: 550px;
			    top: 0;
			    bottom: 0;
			    margin-top: auto;
			    margin-bottom: auto;
			    display: -webkit-flex;
			    display: -moz-flex;
			    display: -ms-flex;
			    display: -o-flex;
			    display: flex;
			    flex-direction: column;
			    justify-content: center;
			}
		}
	}
	.second__block {
		.left__side--image {
			max-width: 100%;
		}
	}
}

@media all and (max-width: 1700px) {
	.third__block {
		#products__owl {
			max-width: 90%;
			margin: 0 auto;
			margin-bottom: 60px;
			.owl-nav {
				.owl-prev {
					left: -100px;
				}
				.owl-next {
					right: -100px;
				}
			}
		}
	}

	.fourth__block {
		.advantage__item {
			margin-right: 19px;
		}
	}

	//Inner page
	.page__inner {
		.inner__banner {
			padding-left: 250px;
		}
		.content__block {
			.left__side {
			    padding: 100px 150px 20px 200px;
			    .more__button {
					margin-bottom: 50px;
				}
			}
			&:nth-child(even) {
			    .left__side {
			    	padding: 170px 250px 20px 150px;
			    }
			}
		}
		.products__cat--wrap {
			.product__cat {
			    max-width: 345px;
    			margin-right: 20px;
			}
			.wp-pagenavi {
				a {
					margin-right: 35px;
				}
				.current {
					margin-right: 35px;
				}
				.previouspostslink {
					margin-right: 50px;
				}
			}
		}
		.products__all--wrap {
			.product__cat {
			    width: 32%;
    			max-width: 460px;
			}
		}
		//Contacts page
		.contacts__inner {
			.contacts__info--wrap {
				form {
					.wpcf7-form-control-wrap {
					    max-width: 295px;
						&:first-child {
							margin-right: 17px;
						}
					}
				}
			}
		}
		//News page
		.news__inner {
			.news__wrap {
				.wp-pagenavi {
					a {
						margin-right: 35px;
					}
					.current {
						margin-right: 35px;
					}
					.previouspostslink {
						margin-right: 50px;
					}
				}
			}
		}
		.problems__inner {
			.wp-pagenavi {
				a {
					margin-right: 35px;
				}
				.current {
					margin-right: 35px;
				}
				.previouspostslink {
					margin-right: 50px;
				}
			}
		}
	}
}

@media all and (max-width: 1590px) {
	header {
		.sub__menu--banner {
		    padding: 30px 0 30px 150px;
		}
		.header--logo {
			margin-right: 230px;
		}
	}
	.first__block {
		.slider__item {
			.slider__text--wrap {
			    top: 0;
			    bottom: 0;
			    margin-top: auto;
			    margin-bottom: auto;
			    display: -webkit-flex;
			    display: -moz-flex;
			    display: -ms-flex;
			    display: -o-flex;
			    display: flex;
			    flex-direction: column;
			    justify-content: center;
			}
		}
	}
	.third__block {
		#products__owl {
			.owl-nav {
				.owl-prev {
					left: -80px;
				}
				.owl-next {
					right: -80px;
				}
			}
		}
	}
	.fifth__block {
		.news__item {
			.thumb__wrap {
				height: 400px;
			}
		}
	}
	footer {
		.footer__column {
			margin-right: 120px;
		}
	}

	//Inner page
	.page__inner {
		.inner__banner {
			padding-left: 200px;
		}
		.content__block {
			.left__side {
		        padding: 100px 130px 20px 150px;
			}
			&:nth-child(even) {
			    .left__side {
		    	    padding: 170px 170px 20px 100px;
			    }
			}
		}
		//Contacts page
		.contacts__inner {
			.contacts__info--wrap {
				.left {
					margin-right: 50px;
				}
				.contacts__cards--wrap {
					.card {
					    padding: 40px 40px 35px 60px;
					}
				}
				form {
					.wpcf7-form-control-wrap {
						width: 50%;
						max-width: 297.8px;
						input {
							padding: 15px 0 15px 25px;
						}
						&:first-child {
							margin-right: 5px;
						}
						&:nth-child(3) {
							textarea {
								padding-left: 25px;	
							}
						}
					}
				}
			}
		}
		//Single post
		.single__inner {
			.post__content {
				img.alignleft {
					max-width: 600px;
				    margin: 50px 70px 70px 0;
				}
				img.alignright {
					max-width: 600px;
				    margin: 50px 0 70px 70px;
				}
			}
			.prod__navigation--wrap {
				a {
					font-size: 16px;
				}
			}
		}
		//Page problems
		.problems__inner {
			.problem__block {
			    padding: 60px 75px 60px 500px;
		        background: #4d4f8c url(../img/logo-p.svg) 127% 100% no-repeat;
			    background-size: 400px;
			    img {
			    	width: 450px;
			    	height: 485px;
			    }
			}
		}
		//Single problem page
		.single__problems--inner {
			.content__wrap {
				.left__image {
					margin-right: 50px;
				}
			}
			.prod__navigation--wrap {
				a {
					font-size: 16px;
				}
			}
		}
	}
	//Error page
	.error404 {
		.error__inner {
			.page__wrap {
				h1 {
					font-size: 250px;
				}
				p {
					margin-bottom: 35px;
				}
			}
		}
	}
}


@media all and (max-width: 1480px) {
	.fourth__block {
		.advantage__item {
		    width: 24%;
    		max-width: 322px;
			margin-right: 20px;
		}
	}
	footer {
		.footer__column {
			margin-right: 100px;
		}
	}
	//Inner page
	.page__inner {
		.bottom__links--wrap {
			.bottom__item {
			    max-width: 300px;
    			height: 290px;
			    padding: 50px 35px;
			    &:hover {
			    	padding-bottom: 30px;
			    }
			}
		}
		//Single product
		.single__product {
			.sidebar {
				.small__images {
					a {
						max-width: 200px;
					}
				}
			}
		}
		//Category product
		.products__cat--wrap {
		    padding: 60px 0 0;
			.product__cat {
			    max-width: 320px;
			}
			.wp-pagenavi {
				a {
					margin-right: 30px;
				}
				.current {
					margin-right: 30px;
				}
				.previouspostslink {
					margin-right: 45px;
				}
			}
		}
		//Single product
		.single__product {
		    padding: 80px 0 0;
		}
		//News page
		.news__inner {
			.news__wrap {
				.wp-pagenavi {
					a {
						margin-right: 30px;
					}
					.current {
						margin-right: 30px;
					}
					.previouspostslink {
						margin-right: 45px;
					}
				}
			}
		}
		//Page problems
		.problems__inner {
			.wp-pagenavi {
				a {
					margin-right: 30px;
				}
				.current {
					margin-right: 30px;
				}
				.previouspostslink {
					margin-right: 45px;
				}
			}
		}
		.products__all--wrap {
			.product__cat {
    			max-width: 430px;
			}
		}

		//Contacts page
		.contacts__inner {
			.contacts__info--wrap {
				form {
					.wpcf7-form-control-wrap {
						max-width: 283px;
					}
				}
			}
		}
	}
}

@media all and (max-width: 1400px) {
	header {
		padding: 20px 0;
		.sub__menu--banner {
			top: 83px;
		    width: calc(100% - 300px);
	        padding: 20px 0 20px 100px;
		}
		.header--logo {
			margin-right: 200px;
		}
		.language {
			width: 300px;
			.hidden__lang {
				bottom: -160px;
				li {
					a {
						padding: 35px 0 30px 70px;
					}
				}
			}
		}
	}
	nav {
		ul {
			li {
				margin-right: 50px;
				&:after {
					margin-top: 10px;
				}
				.sub-menu {
					margin-top: 30px;
					padding: 40px 30px;
					width: 300px;
					left: -90px;
				}
			}
		}
	}
	.first__block {
		height: calc(100vh - 110px);
		margin-top: 115px;
		.slider__item {
			height: calc(100vh - 110px);
			.slider__text--wrap {
				left: 220px;
				.mark {
					margin-bottom: 20px;
				}
				h4 {
					font-size: 54px;
					line-height: 1.3;
				}
				.slider__undertitle {
					margin-bottom: 30px;
				}
			}
		}
		#banner__owl {
			.owl-nav {
				.owl-prev {
					left: 80px;
				}
				.owl-next {
					right: 80px;
				}
			}
		}
	}
	.third__block {
		#products__owl {
			width: 100%;
		}
		.slider__item {
			.bottom__bg {
				height: 120px;
			}
		}
	}
	.fourth__block {
		.advantage__item {
			padding: 30px;
    		max-width: 270px;
		}
	}
	.fifth__block {
		.news__item {
			.thumb__wrap {
				height: 300px;
			}
		}
	}
	footer {
		.footer__logo {
			margin-right: 100px;
		}
		.footer__column {
			margin-right: 70px;
		}
	}

	//Inner page
	.page__inner {
		margin-top: 115px;
		.inner__banner {
			padding-left: 150px;
		}
		.content__block {
			.left__side {
	            padding: 100px 70px 20px 70px;
			    .more__button {
					margin-bottom: 50px;
				}
			}
			&:nth-child(even) {
				.right__side {
					padding: 100px 0;
				}
			    .left__side {
		    	    padding: 120px 70px 20px 70px;
			    }
			}
		}
		.bottom__links--wrap {
			.bottom__item {
			    max-width: 270px;
    			height: 260px;
			    padding: 50px 35px;
			    &:hover {
			    	padding-bottom: 30px;
			    }
			}
		}

		.documentation__wrap {
			.documentation__table {
				tr {
					&:nth-child(2) {
						td {
							width: 99.3%;
						}
					}
					th {
						&:first-child {
							width: 36.5%;
						}
						&:nth-child(2) {
							width: 36.5%;
						}
						&:nth-child(3) {
							width: 13%;
						}
						&:nth-child(4) {
							width: 16%;
							a {
								&:before{
									right: 20px;
								}
							}
						}
					}
				}
			}
			.documentation__table--second {
				tr {
					td {
						&:first-child {
							width: 36.5%;
						}
						&:nth-child(2) {
							width: 36.5%;
						}
						&:nth-child(3) {
							width: 13%;
						}
						&:nth-child(4) {
							width: 16%;
							a {
								&:before{
									right: 20px;
								}
							}
						}
					}
				}
			}
		}

		//Auctioneer
		.auctioneer__page {
			.documentation__table {
				tr {
					&:nth-child(2) {
						td {
							width: 99.4%;
						}
					}
					th {
						&:first-child {
							width: 64.4%;
						}
						&:nth-child(2) {
							width: 13%;
						}
						&:nth-child(3) {
							width: 17.6%;
							&:before{
								right: 30px;
							}
						}
					}
				}
			}
			.documentation__table--second {
				tr {
					td {
						&:first-child {
							width: 64.4%;
						}
						&:nth-child(2) {
							width: 13%;
						}
						&:nth-child(3) {
							width: 17.6%;
						}
					}
				}
			}
		}
		//Single product
		.single__product {
			.sidebar {
				.sp-thumbnails-container {
					.sp-thumbnail {
						&:before, &:after {
							width: 50px;
						}
					}
				}
				.small__images {
					a {
						max-width: 170px;
						&:before, &:after {
							width: 50px;
						}
					}
				}
			}
		}
		//Category product
		.products__cat--wrap {
			.tabs {
				li {
					&.current {
						padding-left: 40px;
						&:before {
							left: 20px;
						}
					}
				}
			}
			.product__cat {
			    max-width: 265px;
			}
		}
		.products__all--wrap {
			.product__cat {
				height: 250px;
    			max-width: 360px;
			}
		}
		//Contacts page
		.contacts__inner {
			.contacts__info--wrap {
				form {
					.wpcf7-form-control-wrap {
						max-width: 236.8px;
					}
				}
			}
		}
		//Resources
		.resources__inner {
			.resources__tabs--wrap {
				.res__tab {
					height: 350px;
				}
			}
		}
		//Page problems
		.problems__inner {
			.team__member {
				margin-bottom: 150px;
			}
		}
	}
	//Error page
	.error404 {
		.error__inner {
			height: calc(100vh - 83.6px);
			.page__wrap {
				height: calc(100vh - 83.6px);
			}
		}
	}
}

@media all and (max-width: 1300px) {
	header {
		.header--logo {
			margin-right: 150px;
		}
	}
	.second__block {
		.right__side--content {
			padding-top: 40px;
			.block__number {
				margin-bottom: 30px;
			}
			.right__block--title {
				margin-bottom: 30px;
			}
			.content__titile {
				margin-bottom: 30px;
			}
			.about__undertitle {
				margin-bottom: 30px;
			}
			p {
				margin-bottom: 30px;
			}
		}
	}
}

@media all and (max-width: 1050px) {
	header {
		.sub__menu--banner {
		    width: calc(100% - 200px);
		}
		.header--logo {
			margin-right: 100px;
		}
		.language {
			width: 200px;
			padding-left: 35px;
			.hidden__lang {
				top: -140px;
				li  {
					a {
					    padding: 30px 0 25px 34px;
					}
				}
			}
		}
	}
	nav {
		ul {
			li {
				margin-right: 40px;
				.sub-menu {
					li {
						font-size: 14px;
						margin-bottom: 15px;
					}
				}
			}
		}
	}
	.first__block {
		.slider__item {
			.slider__text--wrap {
				left: 200px;
			}
		}
		#banner__owl {
			.owl-nav {
				.owl-next {
					right: 60px;
				}
				.owl-prev {
					left: 60px;
				}
			}
		}
	}
	.third__block {
		#products__owl {
			.owl-nav {
				.owl-next {
					right: -70px;
				}
				.owl-prev {
					left: -70px;
				}
			}
		}
	}
	.fourth__block {
		.advantage__item {
			margin-right: 15px;
			max-width: 223px;
			.title {
				font-size: 14px;
			}
		}
	}
	.fifth__block {
		.news__item {
			width: calc(35% - 20px);
			margin-right: 20px;
			.thumb__wrap {
				height: 250px;
			}
			.description__wrap {
				padding: 30px 0 40px;
				.date-title__wrap {
					padding-right: 25px;
				}
				.post__text--wrap {
					padding: 0 25px;
				}
			}
		}
	}
	//Inner page
	.page__inner {
		.inner__banner {
			padding-left: 100px;
			ul.breadcrumbs {
				margin-bottom: 20px;
			}
			h1 {
				font-size: 56px;
			}
		}
		.content__block {
			.left__side {
                padding: 50px 50px 20px;
			    .more__button {
					margin-bottom: 30px;
				}
				h4 {
					margin-bottom: 30px;
				}
				p {
					margin-bottom: 30px;
				}
			}
			&:nth-child(even) {
				.right__side {
					padding: 50px 0;
				}
			    .left__side {
		    	    padding: 50px 50px 20px;
			    }
			}
		}
		.bottom__links--wrap {
			padding: 100px 0;
			.bottom__item {
			    max-width: 220px;
    			height: 250px;
			    padding: 30px 20px;
			    overflow: hidden;
			    &:hover {
			    	padding-bottom: 30px;
			    }
			    h4 {
			    	margin-bottom: 20px;
			    }
			    p {
			    	line-height: 1.5;
			    }
			}
		}
		//Single product
		.single__product {
			padding: 90px 0 0;
			.sidebar {
				.sp-thumbnails-container {
					.sp-thumbnail {
						&:before, &:after {
							width: 30px;
						}
					}
				}
				.small__images {
					a {
						max-width: 140px;
						&:before, &:after {
							width: 35px;
						}
					}
				}
				.specifications {
					.specifications__accordeon {
						.accordeon__content {
						   padding: 30px 30px 40px 40px;
						}
					}
				}
			}
			.main__content {
				.advantages {
					.advantages__item {
						.left {
						   padding: 40px 0 0 20px;
						}
						.right {
							padding: 40px 40px 40px 45px;
						}
					}
				}
			}
			.prod__navigation--wrap {
				a {
					font-size: 16px;
				}
			}
		}
		//Category product
		.products__cat--wrap {
			.product__cat {
				width: 50%;
				max-width: 325px;
				&:nth-child(3n) {
					margin-right: 20px;
				}
				&:nth-child(2n) {
					margin-right: 0;
				}
			}
		}
		.products__all--wrap {
			.product__cat {
				height: 250px;
    			max-width: 360px;
    			margin-right: 18px;
			}
		}
		
		//Contacts page
		.contacts__inner {
			#map {
				height: 600px;
			}
			.contacts__info--wrap {
				.left {
					margin-right: 30px;
				}
				.contacts__cards--wrap {
					.card {
					    padding: 40px 15px 35px 55px;
					}
				}
				form {
					.wpcf7-form-control-wrap {
						width: 48%;
						&:first-child {
							margin-right: 12px;
						}
					}
				}
			}
		}

		//Page Team
		.team__inner {
			.team__member {
				padding: 55px 110px 60px 350px;
				img {
					width: 340px;
					height: 455px;
					left: -20px;
				}
			}
		}

		//Page Partners
		.partners__inner {
			.map__inner {
				.map__point--wrap {
					.point__logo {
						width: 250px;
						height: 70px;
						img {
							max-width: 130px;
						}
					}
					&.ternopol {
						top: 240px;
						left: 200px;
						&:hover {
						    top: 200px;
							left: 180px;
						}
					}
					&.vinnitsya {
						top: 310px;
						left: 350px;
						&:hover {
						    top: 270px;
							left: 335px;
						}
					}
					&.kiev {
						top: 220px;
						left: 430px;
						&:hover {
						    top: 180px;
							left: 410px;
						}
					}
					&.nikolaev {
						    bottom: 260px;
						    left: 525px;
						&:hover {
						    bottom: 300px;
						    left: 510px;
						}
					}
					&.poltava {
						        top: 250px;
						        right: 340px;
						&:hover {
						    right: 355px;
						    top: 210px;
						}
					}
				}
			}
		}
		//News page
		.news__inner {
			.news__wrap {
				.news__item {
					.description__wrap {
						.left__author {
							margin-right: 50px;
						}
					}
				}
			}
		}
		//Single post
		.single__inner {
			.post__content {
				img.alignleft {
					max-width: 450px;
				    margin: 50px 50px 50px 0;
				}
				img.alignright {
					max-width: 450px;
				    margin: 50px 0 50px 50px;
				}
			}
		}
		//Page problems
		.problems__inner {
			.problem__block {
			    padding: 50px 60px 50px 350px;
		        background: #4d4f8c url(../img/logo-p.svg) 135% 100% no-repeat;
			    background-size: 400px;
			    img {
			    	width: 330px;
			    	height: 500px;
			    }
			}
		}
		//Page resources
		.resources__inner {
			.resources__tab--item {
				.documentation__table {
					tr {
						th {
							&:before {
								right: 15px;
							}
						}
					}
				}
				.left__image {
					margin-right: 40px;
				}
				.right__content {
					padding-top: 30px;
					p {
						margin-bottom: 30px;
					}
				}
			}
		}
		
		//Documentation
		.documentation__wrap {
			.documentation__table {
				tr {
					&:nth-child(2) {
						td {
						    width: 99.2%;
						}
					}
					th {
						padding-left: 20px;
						&:nth-child(2) {
							width: 34.5%;
						}
						&:nth-child(4) {
							width: 18%;
						}
					}
				}
			}
			.documentation__table--second {
				tr {
					td {
						&:nth-child(2) {
							width: 34.5%;
						}
						&:nth-child(4) {
							width: 18%;
						}
					}
				}
			}
			.more__documents {
				width: 99.2%;
				margin: 0 auto;
			}
		}

		.auctioneer__page {
			.documentation__table {
				tr {
					th {
						&:nth-child(2) {
							width: 13%;
						}
					}
				}
			}
			.documentation__table--second {
				tr {
					td {
						&:nth-child(2) {
							width: 13%;
						}
						&:nth-child(3) {
							text-align: left;
							a {
								padding: 23px 0 23px 20px;
							}
						}
					}
				}
			}
		}
	}

	.page__inner {
		.single__product {
			.container {
				width: 970px;
			}
			.sidebar {
				margin-right: 50px;
			}
		}
	}
}

@media all and (max-width: 990px) {
	section {
		&:after {
			display: none;
		}
	}
	.header--nav {
		display: none;
	}
	header {
		.language {
			display: none;
		}
		nav {
			ul {
				li {
					margin-right: 0;
					.sub-menu {
						margin-top: 10px;
						padding: 0;
						left: 0;
						li {
							a {
								&:before {
									display: none;
								}
							}
						}
					}
				}
				.menu-item-has-children {
					&:before {
						display: none;
					}
					a {
						position: relative;
						&:before {
							display: block;
						    content: "";
						    width: 0;
						    height: 0;
						    border-left: 3.5px solid transparent;
						    border-right: 3.5px solid transparent;
						    border-top: 4px solid #fff;
						    position: absolute;
						    top: 7px;
						    right: -22px;
						}
					}
				}
			}
		}
		.mobile__bar {
			display: block;
			width: 150px;
		    height: 100%;
		    position: absolute;
		    right: 0;
		    top: 0;
		    background: #8e94c1;
		    display: -webkit-flex;
	    	display: -moz-flex;
	    	display: -ms-flex;
	    	display: -o-flex;
	    	display: flex;
	    	align-items: center;
			justify-content: center;
			transition: .5s all ease-out;
			&.close__menu--wrap {
				background: #fff;
			}
		    .menu__button {
		    	height: 3px;
		    	width: 30px;
		    	background-color: #fff;
		    	position: relative;
		    	transition: .5s all ease-out;
		    	&:before {
		    		display: block;
		    		content:'';
		    		width: 100%;
		    		height: 3.5px;
		    		position: absolute;
		    		left: 0;
		    		top: -9px;
		    		background-color: #fff;
		    		transition: .5s all ease-out;
		    	}
		    	&:after {
		    		display: block;
		    		content:'';
		    		width: 100%;
		    		height: 3.5px;
		    		margin-top: 6px;
		    		position: absolute;
		    		left: 0;
		    		bottom: -9px;
		    		background-color: #fff;
		    		transition: .5s all ease-out;
		    	}
		    	&.close__menu {
		    		background: transparent;
		    		&:before {
		    			transform: rotate(45deg);
		    			top: -1px;
		    			background-color: #322c74;
		    		}
		    		&:after {
		    			transform: rotate(-45deg);
		    			bottom: 0;
		    			background-color: #322c74;
		    		}
		    	}
		    }
		}
		.mobile__menu {
		    position: absolute;
		    left: 0;
		    top: 90px;
		    width: 100%;
		    background: #322c74;
		    padding: 30px 0 40px 30px;
			.header--nav, .language {
				display: block;
			}
			.header--nav {
				padding-top: 0;
				.headnav {
					display: -webkit-flex;
					display: -moz-flex;
					display: -ms-flex;
					display: -o-flex;
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					li {
						margin-right: 0;
						margin-bottom: 35px;
						&:last-child {
							margin-bottom: 0;
						}
						&:after {
							margin-top: 15px;
						}
						 .sub-menu {
						 	position: relative;
						 	opacity: 1;
						 	visibility: visible;
						 	padding-top: 35px;
						 }
					}
				}
			}
			.language {
				position: relative;
    			background: transparent;
    			padding: 0;
    			width: 100%;
    			height: auto;
    			max-width: 130px;
    			margin: 0 auto;
    			.hidden__lang {
    				top: -150px;
    				width: 150px;
    				li {
    					a {
						    padding: 25px 0;
    						text-align: center;
    					}
    				}
    			}
			}
		}
	}
	.first__block {
		margin-top: 110px;
		height: calc(100vh - 110px);
		.slider__item {
			height: calc(100vh - 110px);
		}
	}
	.second__block {
	    flex-direction: column-reverse;
	    .left__side--image {
	    	width: 100%;
	    	max-width: 100%;
	    	margin-right: 0;
	    	padding-top: 35px;
	    	background: linear-gradient(to bottom, #fdfdfd, #fff);
	    }
	    .right__side--content {
	    	width: 100%;
	    	max-width: 100%;
	    	padding-right: 0;
	    	margin-bottom: 100px;
	    	padding: 0 30px;
	    }
	}
	.third__block {
		padding-top: 70px;
		&:before {
			top: 0;
			height: 100%;
		}
	}
	.fourth__block {
		padding-top: 100px;
		&:before {
			width: 1115%;
			top: -150px;
		}
		.block__title {
			margin-bottom: 100px;
		}
		.advantages__wrap {
			flex-wrap: wrap;
			justify-content: space-between;
		}
		.advantage__item {
			width: 48%;
			max-width: 50%;
			margin-bottom: 30px;
			margin-right: 0;
		}
	}
	.fifth__block {
		padding-top: 110px;
		.block__title {
			margin-bottom: 70px;
		}
		.news__wrap {
			flex-wrap: wrap;
		}
		.news__item {
			width: 100%;
			margin-right: 0;
			margin-bottom: 40px;
			.thumb__wrap {
				height: auto;
			}
		}
	}
	footer {
		.footer__container {
			&:after {
				top: 55%;
			}
		}
		.footer__content--wrap {
			flex-wrap: wrap;
		}
		.footer__logo {
			width: 100%;
			margin-right: 0;
			text-align: center;
			margin-bottom: 50px;
			img {
				width: 200px;
			}
		}
		.footer__column {
			margin-right: 0;
    		width: 50%;
		}
	}

	//Page inner
	.page__inner {
		margin-top: 105px;
		.inner__banner {
			ul.breadcrumbs {
				margin-bottom: 15px;
				a {
					font-size: 14px;
				}
			}
			h1 {
				font-size: 48px;
			}
		}
	}
	//Page about
	.page__inner {
		.content__block {
			.left__side {
				padding: 30px;
				h4 {
					font-size: 32px;
					margin-bottom: 25px;
				}
				.undertitle {
					margin-bottom: 25px;
				}
				p {
					margin-bottom: 25px;
				}
				.more__button {
					width: 180px;
				    padding: 15px 0;
				    margin-bottom: 25px;
				    opacity: 1;
				}
			}
		}
		.bottom__links--wrap {
			flex-wrap: wrap;
			.bottom__item {
				max-width: 49%;
				width: 49%;
				height: 300px;
				margin-bottom: 50px;
			}
		}
	}

	//Page auctioneer
	.page__inner {

		.documentation__wrap {
			.block__line {
				margin-bottom: 50px;
			}
			.documentation__table {
				tr {
					th {
						&:before {
							right: 15px;
						}
						&:first-child {
						    width: 32%;
							padding-left: 20px;
						}
						&:nth-child(2) {
					        width: 30%;
							padding-left: 20px;
						}
						&:nth-child(3) {
					        width: 15%;
					        &:before {
								right: 15px;
							}
						}
						&:nth-child(4) {
					        width: 23%;
						}
					}
					&:nth-child(2) {
						td {
							width: 98.9%;
						}
					}
				}
			}
			.documentation__table--second {
				tr {
					td {
						&:first-child {
						    width: 32%;
							padding-left: 20px;
						}
						&:nth-child(2) {
					        width: 30%;
							padding-left: 20px;
						}
						&:nth-child(3) {
					        width: 15%;
						}
						&:nth-child(4) {
					        width: 23%;
					        a {
				        	    padding: 23px 0 23px 25px;
					        }
						}
						span {
							display: none;
						}
					}
				}
			}
		}

		.auctioneer__page {
			.block__line {
				margin-bottom: 50px;
			}
			.documentation__table {
				tr {
					th {
						&:first-child {
						    width: 45.4%;
							padding-left: 30px;
						}
						&:nth-child(2) {
					        width: 15%;
						}
						&:nth-child(3) {
					        width: 23.4%;
						}
					}
					&:nth-child(2) {
						td {
							width: 98.9%;
						}
					}
				}
			}
			.documentation__table--second {
				tr {
					td {
						padding: 23px 0 23px 30px;
						&:first-child {
						    width: 45.4%;
							padding-left: 30px;
						}
						&:nth-child(2) {
							padding: 15px 0;
							width: 15%;
							text-align: center;
						}
						&:nth-child(3) {
					        width: 21.6%;
				            a {
				            	padding: 23px 0 23px 30px;
						        &:before {
						        	top: 25px;
						        	right: 25px;
						        }
				            }
						}
						span {
							display: none;
						}
					}
				}
			}
		}
	}

	//Page documentation
	.page__inner {
		.documentation__wrap {
			padding: 100px 0;
			.search__wrap {
				align-items: center;
				margin-bottom: 75px;
				form {
					input {
						width: 200px;
					}
					.search-submit {
						width: 150px;
					}
				}
			}
		}
	}
	//Page contacts
	.page__inner {
		.contacts__inner {
			padding: 100px 0;
			.undertitile {
				margin-bottom: 75px;
			}
			#map {
				margin-bottom: 100px;
			}
			.contacts__info--wrap {
				.col-md-12 {
			        flex-direction: column;
			      }
				.left {
					margin-bottom: 50px;
					margin-right: 0;
				}
				form {
					.wpcf7-form-control-wrap {
					    width: 48.7%;
					    max-width: 320px;
					}
				}
			}
		}
	}
	//Page news
	.page__inner {
		.news__inner {
			.news__wrap {
				.wp-pagenavi {
					margin-bottom: 0;
				}
			}
		}
	}

	//Page Partners
	.page__inner {
		.partners__inner {
			.map__inner {
				.map__point--wrap {
					.point__logo {
						width: 200px;
						height: 50px;
						img {
							max-width: 100px;
						}
					}
					&.ternopol {
						top: 160px;
						left: 130px;
						&:hover {
						    top: 120px;
							left: 110px;
						}
					}
					&.vinnitsya {
						top: 200px;
						left: 240px;
						&:hover {
						    top: 160px;
							left: 222px;
						}
					}
					&.kiev {
						top: 140px;
						left: 295px;
						&:hover {
						    top: 100px;
							left: 275px;
						}
					}
					&.nikolaev {
						    bottom: 190px;
						    left: 360px;
						&:hover {
						    bottom: 230px;
						    left: 342px;
						}
					}
					&.poltava {
						        top: 165px;
						        right: 240px;
						&:hover {
						    right: 257px;
						    top: 125px;
						}
					}
				}
			}
		}
	}
	//Page problems
	.page__inner {
		.problems__inner {
			padding-top: 80px;
			.undertitile {
				margin-bottom: 100px;
			}
			.problem__block {
			    flex-direction: column;
			    padding: 0;
			    margin-bottom: 100px;
			    img {
			    	position: relative;
			    	left: 0;
			    	top: 0;
			    	width: 100%;
			    	height: auto;
			    	max-height: 300px;
			    }
			    .description__wrap {
			    	padding: 30px;
			    }
			}
		}
	}

	//Page products
	.page__inner {
		.products__wrap {
			padding: 100px 0 150px;
			.product__cat {
				.bottom__bg {
					height: 200px;
				}
				.image__wrap {
					height: 200px;
				}
				.description__block {
					a {
						font-size: 14px;
					}
				}
				img {
					max-height: 200px;
				}
			}
		}
		.products__all--wrap {
			.product__cat {
				height: 200px;
    			max-width: 210px;
    			margin-right: 10px;
    			h4 {
    				a {
    					display: block;
    					width: 100%;
    					font-size: 16px;
    					text-align: center;
    				}
    			}
			}
		}
	}

	//Page team
	.page__inner {
		.team__inner {
			.team__member {
				padding: 50px 50px 50px 250px;
			    background-size: 200px;
			    margin-bottom: 100px;
				img {
					width: 250px;
					height: auto;
					top: -20px;
				}
				.position {
					margin-bottom: 20px;
				}
				.description {
				    max-height: 120px;
    				overflow: hidden;
    				margin-bottom: 30px;
				}
			}
			.all__members {
				&:before {
					top: 350px;
				}
			}
		}
	}

	//Page product category
	.page__inner {
		.products__cat--wrap {
			padding: 80px 0;
			.col-md-3 {
				margin-bottom: 50px;
			}
			.product__cat {
				width: 48.4%;
				.bottom__bg {
					height: 110px;
				}
			}
		}
	}

	//Page resources
	.page__inner {
		.resources__inner {
			padding: 100px 0;
			.undertitle {
				margin-bottom: 50px;
			}
			.resources__tabs--wrap {
				margin-bottom: 50px;
				.res__tab {
					height: 250px;
				    h4 {
				    	padding: 20px 5px;
						font-size: 16px;
				    }
				}
			}
			.resources__tab--item {
				.documentation__table {
					tr {
						th {
							&:nth-child(2) {
								width: 19.8%;
							}
						}
						&:nth-child(2) {
							td {
								width: 98.9%;
							}
						}
					}
				}
				.documentation__table--second {
					tr {
						td {
							padding: 23px 0 23px 30px;
							&:nth-child(2) {
								padding: 15px 0;
								width: 19.8%;
								text-align: center;
							}
							span {
								display: none;
							}
						}
					}
				}
				.left__image {
					width: 100%;
					display: block;
					img {
						max-height: 350px;
					}
				}
				.right__content {
					width: 100%;
				}
			}
		}
	}

	//Single post
	.page__inner {
		.single__inner {
			padding: 100px 0;
			.author__block {
				margin-bottom: 50px;
			}
			.post__content {
				margin-bottom: 70px;
				.post__image {
					margin-bottom: 50px;
				}
				h6 {
					margin-bottom: 30px;
				}
				p {
					margin-bottom: 30px;
				}
				img.alignleft, img.alignright {
					display: block;
					float: none;
					margin: 0 auto; 
					margin-bottom: 20px;
					width: auto;
					height: auto;
					max-width: 100%;
				}
				blockquote {
					padding: 50px 100px;
					font-size: 20px;
					margin-bottom: 50px;
					&:before {
						font-size: 100px;
						top: 65px;
						left: 35px;
					}
					 p {
					 	font-size: 20px;
					 }
				}
			}
			.prod__navigation--wrap {
				.prev {
					padding-left: 60px;
					&:before {
						left: 30px;
						top: 50px;
					}
				}
				.next {
					padding-right: 60px;
					flex-direction: column;
				    align-items: flex-end;
			        justify-content: center;
					&:before {
						right: 30px;
						top: 50px;
					}
				}
			}
		}
	}

	//Single problem page
	.page__inner {
		.single__problems--inner {
			padding: 100px 0;
			.content__wrap {
				flex-direction: column;
				.left__image {
					width: 100%;
					margin-right: 0;
					margin-bottom: 50px;
					img {
						max-height: 350px;
					}
				}
				.right__content {
					width: 100%;
					h4 {
						margin-bottom: 30px;
					}
					p {
						margin-bottom: 30px;
					}
				}
			}
			.prod__navigation--wrap {
				.prev {
					padding-left: 60px;
					&:before {
						left: 30px;
						top: 50px;
					}
				}
				.next {
					padding-right: 60px;
					flex-direction: column;
				    align-items: flex-end;
			        justify-content: center;
					&:before {
						right: 30px;
						top: 50px;
					}
				}
			}
		}
	}

	//Single product page
	.page__inner {
		.single__product {
			padding: 50px 0 0;
			.container {
				width: 678px;
			}
			.product__wrap {
				flex-direction: column;
				margin-bottom: 60px;
			}
			.sidebar {
				max-width: 100%;
				width: 100%;
				#my-slider {
					margin-bottom: 75px;
				}
				.top__image {
					img {
						max-height: 480px;
					}
					.bottom__bg {
						height: 265px;
					}
				}
				.sp-thumbnails-container {
					max-width: 400px;
					margin: 0 auto;
					margin-top: 20px;
				}
				.small__images {
					max-width: 450px;
					margin: 0 auto;
					margin-bottom: 50px;
				}
				.documents {
					max-width: 400px;
					margin: 0 auto;
					margin-bottom: 60px;
					h4 {
						text-align: center;
						margin-bottom: 25px;
					}
					ul {
						li {
							a {
								padding: 20px 30px;
							}
						}
					}
				}
			}
			.main__content {
				max-width: 100%;
				width: 100%;
				.content__title {
					margin-bottom: 25px;
				}
				.single__char {
					div {
						h6 {
							width: 56%;
						}
						p {
							width: 44%;
						}
					}
				}
			}
			.single__product--bottom {
				margin-bottom: 80px;
				img.alignright, img.alignleft {
					max-width: 200px;
				}
				img.aligncenter {
					display: block;
					max-width: 495px;
					margin: 0 auto;
					margin-bottom: 65px;
				}
				.sprec__tabs__items {
					margin-bottom: 15px;
					li {
						width: 33.3%;
						font-size: 14px;
					}
				}
				.spec__tab--content--wrap {
					.spec__tab--content {
						.accordeon__top {
							font-size: 14px;
							height: 74px;
							display: -webkit-flex;
							display: -moz-flex;
							display: -ms-flex;
							display: -o-flex;
							display: flex;
							align-items: center;
							&:before {
								top: 30px;
							}
						}
						&:nth-child(2) {
							.accordeon__content {
								.content__row {
									.left, .right {
									    padding: 20px 15px 19px 20px;
									}
								}
							}
						}
						&:nth-child(3) {
							.accordeon__content {
								padding: 30px 20px;
							}
						}
					}
					.accordeon__content {
						padding: 30px 30px 30px 50px;
						ul {
							padding-left: 20px;
						}
					}
				}
			}
			.prod__navigation--wrap {
				.prev {
					padding-left: 60px;
					&:before {
						left: 30px;
						top: 50px;
					}
				}
				.next {
					padding-right: 60px;
					flex-direction: column;
				    align-items: flex-end;
			        justify-content: center;
					&:before {
						right: 30px;
						top: 50px;
					}
				}
			}
		}
	}

	//Navigation
	.prod__navigation--wrap {
		.next {
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex !important;
		    align-items: center !important;
    		padding: 0 60px 0 15px !important;
		}
	}
}

@media all and (max-width: 500px) {
	.block__title {
		font-size: 36px;
	}
	header {
		padding: 25px 0 30px 0;
		.header--logo {
			padding-left: 15px;
		}
		.mobile__bar {
			width: 105px;
			.menu__button {
				&:before, &:after {
					height: 3px;
				}
				&.close__menu {
					&:before {
						top: 0;
					}
				}
			}
		}
		.mobile__menu {
			top: 105px;
			height: auto;
			padding: 30px 0 120px 30px;
			.language {
				position: absolute;
				top: unset;
				bottom: 30px;
				left: 0;
				.hidden__lang {
					z-index: 99;
					top: -140px;
				}
			}
			.header--nav {
				.headnav {
					li {
						line-height: .2;
					}
				}
			}
		}
	}
	.first__block {
		height: calc(100vh - 92px);
		.slider__item {
			height: calc(100vh - 92px);
			.slider__text--wrap {
				left: 0;
				padding: 0 30px;
				.mark {
					margin-bottom: 15px;
				}
				h4 {
					font-size: 47px;
					margin-bottom: 20px;
				}
				 .button__wrap {
				 	.video__open {
				 		display: none;
				 	}
				 }
			}
		}
		.to__second {
			display: none;
		}
		#banner__owl {
			.owl-nav {
				.owl-prev {
				    bottom: 25px;
    				top: unset;
    				left: 30px;
				}
				.owl-next {
			        top: unset;
				    bottom: 25px;
				    left: 82px;
				}
			}
		}
	}
	.second__block {
		.right__side--content {
			.content__titile {
				font-size: 36px;
			}
		}
	}
	.third__block {
		&:before {
			left: -30px;
			width: 120%;
		}
		.block__title {
			margin-bottom: 75px;
		}
		#products__owl {
			.owl-stage {
				margin-bottom: 65px;
			}
			.owl-nav {
				width: 105px;
			    height: 50px;
			    position: relative;
		        margin: 0 auto;
				.owl-prev {
					left: 0;
				    top: unset;
				    bottom: 0;
				}
				.owl-next {
					right: 0;
				    top: unset;
				    bottom: 0;
				}
			}
		}
	}
	.fourth__block {
		padding-top: 0;
		&:before {
			width: 120%;
			height: 90%;
		}
		.block__title {
			margin-bottom: 75px;
		}
		.advantage__item {
			width: 100%;
			max-width: 100%;
			text-align: center;
			&:before {
				left: 0;
				right: 0;
				margin-left: auto;
				margin-right: auto;
			}
			.number {
				margin-bottom: 20px;
				&:after {
					margin: 0 auto;
					margin-top: 10px;
				}
			}
		}
	}
	.fifth__block {
		padding-top: 0;
		padding-bottom: 50px;
		.col-md-12 {
			padding: 0;
		}
		.news__item {
			margin-bottom: 30px;
			.description__wrap {
				.post__text--wrap {
					p {
						margin-bottom: 20px;
					}
				}
			}
		}
	}
	footer {
		.footer__container {
			&:after {
				top: 60%;
				right: -250px;
			}
		}
		.footer__logo {
			margin-bottom: 90px;
			img {
				max-width: 150px;
			}
		}
		.footer__column {
			width: 100%;
			margin-bottom: 65px;
			padding-left: 45px;
			&:last-child {
				margin-bottom: 30px;
			}
		}
		.footer__bottom--wrap {
		    flex-direction: column;
			align-items: center;
			.copyright {
				margin-bottom: 15px;
				text-align: center;
				.auctioneer__link {
					display: block;
					margin-left: 0;
					margin-top: 15px;
				}
			}
		}
	}
	//Error page
	.error404 {
		.error__inner {
			.page__wrap {
				h1 {
					font-size: 140px;
				}
				p {
					font-size: 20px;
				}
			}
		}
	}
	//Page inner
	.page__inner {
		margin-top: 135px;
		.inner__banner {
			height: 250px;
			padding-left: 40px;
			h1 {
				font-size: 40px;
			    line-height: 1.01;
			}
		}
	}
	//Page about
	.page__inner {
		.content__block {
		    flex-direction: column-reverse;
		    .left__side {
		    	width: 100%;
		    	max-width: 100%;
		    }
		    .right__side {
		    	padding-top: 50px;
		    	width: 100%;
		    	max-width: 100%;
		    }
		    &:nth-child(even) {
		    	flex-direction: column-reverse;
		    	.left__side {
		    		padding: 30px;
		    	}
		    	.right__side {
		    		padding-top: 50px;
		    		padding-bottom: 0;
		    	}
		    }
		}
		.bottom__links--wrap {
			padding: 50px 0;
			.bottom__item {
				max-width: 100%;
				width: 100%;
				height: 250px;
			}
		}
	}

	//Page auctioneer
	.page__inner {

		.documentation__wrap {
			.block__line {
				margin-bottom: 50px;
			}
			.documentation__table {
				tr {
					th {
						padding-left: 0 !important;
						text-align: center;
						a {
							padding: 18px 5px !important;
							text-align: center;
							font-size: 12px !important;
						}
						&:before {
							display: none !important;
						}
						&:first-child {
						    width: 33%;
						}
						&:nth-child(2) {
					        width: 31%;
						}
						&:nth-child(3) {
					        width: 16%;
						}
						&:nth-child(4) {
					        width: 20%;
						}
					}
					&:nth-child(2) {
						td {
							width: 97.9%;
						}
					}
				}
			}
			.documentation__table--second {
				tr {
					td {
						font-size: 12px !important;
						text-align: center;
						&:first-child {
						    width: 33%;
						}
						&:nth-child(2) {
					        width: 31%;
						}
						&:nth-child(3) {
					        width: 16%;
						}
						&:nth-child(4) {
					        width: 20%;
					        &:before {
				        	    top: 0;
							    bottom: 0;
							    left: 0;
							    right: 0;
							    margin-left: auto;
							    margin-right: auto;
							    margin-top: auto;
							    margin-bottom: auto;
					        }
					        a {
					        	display: block;
					        	text-indent: -10000px;
					        	&:before {
					        		right: 17px;
					        	}
					        }
					        &:hover {
					        	a {
					        		text-indent: -10000px;
					        	}
					        }
						}
						span {
							display: none;
						}
					}
				}
			}
			.more__documents {
			   width: 98.2%;
			}
		}

		.auctioneer__page {
			.block__line {
				margin-bottom: 50px;
			}
			.documentation__table {
				tr {
					&:nth-child(2) {
						td {
							top: 55px;
						}
					}
					th {
					    a {
					    	font-size: 10px;
					    	padding: 23px 0 23px 10px;
					    }
					    &:before {
							right: 10px;
							top: 27px;
						}
						&:first-child {
							width: 44%;
						}
						&:nth-child(2) {
							width: 20%;
						}
						&:nth-child(3) {
							width: 26%;
							&:before {
								width: 15px;
								height: 15px;
								right: 10px;
								background-size: contain;
							}
						}
					}
					&:nth-child(2) {
						td {
							width: 98%;
						}
					}
				}
			}
			.documentation__table--second {
				tr {
					td {
						padding: 23px 0 23px 10px;
						font-size: 10px;
						&:first-child {
							width: 44%;
						}
						&:nth-child(2) {
							width: 20%;
						}
						&:nth-child(3) {
							width: 26%;
							a {
								padding: 23px 0 23px 5px;
								&:before {
									width: 15px;
									height: 15px;
									right: 5px;
									top: 0;
									background-size: contain;
								}
							}
						}
					}
				}
			}
		}
	}

	//Page documentation
	.page__inner {
		.documentation__wrap {
			padding: 80px 0;
			.search__wrap {
				margin-bottom: 50px;
				flex-direction: column;
				form {
					width: 100%;
					margin-bottom: 30px;
					display: -webkit-flex;
					display: -moz-flex;
					display: -ms-flex;
					display: -o-flex;
					display: flex;
					input {
						width: 65%;
					    padding: 16px 0 15px 20px;
					}
					.search-submit {
						width: 35%;
					}
				}
			}
		}
	}

	//Page contacts
	.page__inner {
		.contacts__inner {
			padding: 80px 0;
			.undertitile {
				margin-bottom: 50px;
			}
			#map {
				margin-bottom: 80px;
				height: 450px;
			}
			.contacts__info--wrap {
				.left {
					.contacts__cards--wrap {
						.card {
							width: 100%;
							height: 175px;
							&.phones {
								.desktop__phone {
									display: none;
								}
								.mobile__phone {
									display: block;
								}
							}
						}
					}
				}
				form {
					.wpcf7-form-control-wrap {
					    width: 100%;
					    max-width: 400px;
					}
					.send__button {
						display: block;
						margin: 0 auto;
					}
				}
			}
		}
	}

	//Page news
	.page__inner {
		.news__inner {
			padding: 100px 0;
			.col-md-12 {
				padding: 0 30px;
			}
			.news__wrap {
				.news__item {
					.thumbnail__wrap {
						margin-bottom: 100px;
					}
					.description__wrap {
						position: relative;
						.left__author {
							position: absolute;
							right: 0;
							top: -95px;
							display: -webkit-flex;
							display: -moz-flex;
							display: -ms-flex;
							display: -o-flex;
							display: flex;
							padding-bottom: 0;
							margin-right: 0;
						    background-color: #6cb7df;
    						border-radius: 50px 0 0 50px;
    						width: 100%;
							&:before {
								display: none;
							}
							img {
								width: 90px;
								height: 90px;
								margin: 0;
							}
							.author__name {
							    padding: 10px 15px;
							    background-color: #6cb7df;
						        display: flex;
							    flex-direction: column;
							    align-items: center;
							    justify-content: center;
							}
						}
						.right__description {
							width: 100%;
							margin: 50px 0;
							h4 {
								font-size: 28px;
								margin-bottom: 25px;
							}
							.undertitle {
								margin-bottom: 25px;
							}
							.excerpt {
								margin-bottom: 25px;
							}
						}
					}
				}
			}
		}
	}

	//Page FAQ
	.page__inner {
		.faq__inner {
			padding: 80px 0;
			.undertitle {
				margin-bottom: 50px;
			}
		}
	}

	//Page Partners
	.page__inner {
		.partners__inner {
			.map__inner {
				.map__point {
					width: 25px;
					height: 25px;
					.point__inner {
						width: 14px;
						height: 14px;
					}
				}
				.map__point--wrap {
					&:hover {
						.map__point {
							width: 50px;
							height: 50px;
							.point__inner {
								width: 30px;
								height: 30px;
								&:before {
									width: 20px;
									height: 20px;
								}
							}
						}
					}
					.point__logo {
						width: 150px;
						height: 50px;
						img {
							max-width: 50px;
						}
					}
					&.ternopol {
						top: 70px;
						left: 65px;
						&:hover {
						    top: 45px;
							left: 55px;
						}
					}
					&.vinnitsya {
						top: 97px;
						left: 120px;
						&:hover {
						    top: 65px;
							left: 108px;
						}
					}
					&.kiev {
						top: 60px;
						left: 150px;
						&:hover {
						    top: 30px;
							left: 137px;
						}
					}
					&.nikolaev {
						    bottom: 115px;
						    left: 185px;
						&:hover {
						    bottom: 143px;
						    left: 175px;
						}
					}
					&.poltava {
						        top: 75px;
						        right: 130px;
						&:hover {
						    right: 140px;
						    top: 45px;
						}
					}
				}
			}
		}
	}

	//Page problems
	.page__inner {
		.problems__inner {
			.problem__block {
				h4 {
					font-size: 24px;
					margin-bottom: 25px;
				}
				.position {
					margin-bottom: 25px;
				}
				.description {
					margin-bottom: 25px;
				}
				.description__wrap {
					background: #4d4f8c url(../img/logo-p.svg) 170px 100% no-repeat;
				}
			}
		}
	}

	//Page products
	.page__inner {
		.products__wrap {
			padding: 100px 0;
			.product__cat {
				width: 100%;
				margin-bottom: 100px;
				.bottom__bg {
					height: 200px;
				}
				.image__wrap {
					height: 200px;
				}
				.description__block {
					a {
						font-size: 16px;
					}
				}
				img {
					max-height: 200px;
				}
			}
		}
		.products__all--wrap {
			.product__cat {
				height: 320px;
    			max-width: 100%;
    			width: 100%;
    			margin-bottom: 30px;
    			margin-right: 10px;
    			.image__wrap {
    				height: 100%;
    			}
    			h4 {
    				a {
    					display: block;
    					width: 100%;
    					font-size: 16px;
    					text-align: center;
    				}
    			}
			}
		}
	}

	//Page team
	.page__inner {
		.team__inner {
			padding-top: 80px;
			.undertitile {
				margin-bottom: 50px;
			}
			.team__cards--wrap {
				margin-bottom: 100px;
			}
			.team__member {
				flex-direction: column;
				padding: 0;
				height: auto;
				img {
					position: relative;
					left: 0;
					top:0;
					width: 100%;
				}
				.description__wrap {
					padding: 20px;
				}
				.description {
				    max-height: 100%;
				}
				h4 {
					font-size: 24px;
				}
			}
			.all__members {
				padding-bottom: 100px;
				&:before {
					top: 300px;
					left: -140px;
				}
				.undertitile {
					margin-bottom: 50px;
				}
			}
		}
	}

	//Page product category
	.page__inner {
		.products__cat--wrap {
			padding: 80px 0;
			.col-md-3 {
				margin-bottom: 50px;
			}
			.product__cat {
				width: 100%;
				max-width: 100%;
				margin-right: 0;
				.image__wrap {
					height: 250px;
				}
				img {
					max-height: 250px;
				}
				.bottom__bg {
					height: 130px;
				}
			}
			.wp-pagenavi {
				margin-bottom: 0;
				padding: 0 15px;
			}
		}
	}

	//Page resources
	.page__inner {
		.resources__inner {
			padding: 80px 0;
			.resources__tabs--wrap {
				flex-wrap: wrap;
				.res__tab {
					width: 100%;
				    margin-bottom: 30px;
				    height: 320px;
				}
			}
			.resources__tab--item {
				.documentation__table {
				tr {
					th {
					    a {
					    	font-size: 10px;
					    	padding: 23px 0 23px 10px;
					    }
					    &:before {
							right: 10px;
							top: 27px;
						}
						&:first-child {
							padding-left: 15px;
						}
						&:nth-child(3) {
							padding-left: 15px;
							&:before {
								width: 15px;
								height: 15px;
								right: 10px;
								background-size: contain;
							}
						}
					}
					&:nth-child(2) {
						td {
							width: 98%;
						}
					}
				}
			}
			.documentation__table--second {
				tr {
					td {
						padding: 23px 0 23px 10px;
						font-size: 10px;
						&:nth-child(2) {
							padding: 15px 5px;
						}
						&:nth-child(3) {
							padding-right: 25px;
							&:before {
								width: 15px;
								height: 15px;
								background-size: contain;
								right: 10px;
							}
						}
					}
				}
			}
				.left__image {
					img {
						max-height: 300px;
					}
				}
				.right__content {
					h4 {
						margin-bottom: 20px;
					}
					.undertitle {
						margin-bottom: 20px;
					}
				}
			}
		}
	}

	//Single page
	.page__inner {
		.single__inner {
			padding: 50px 0;
			.author__block {
				width: 80%;
			}
			.post__content {
				ul {
					li {
						padding-left: 25px;
					}
				}
				blockquote {
					padding: 30px 50px;
					font-size: 16px;
					&:before {
					font-size: 60px;
				    top: 40px;
				    left: 10px;
					}
					p {
						font-size: 16px;
					}
				}
			}
			.prod__navigation--wrap {
				margin-top: 80px;
				margin-bottom: 80px;
				.prev {
					&:before {
						left: 0;
						right: 0;
						margin-left: auto;
						margin-right: auto;
						top: 0;
						bottom: 0;
						margin-top: auto;
						margin-bottom: auto;
					}
				}
				.next {
					&:before {
						left: 0;
						right: 0;
						margin-left: auto;
						margin-right: auto;
						top: 0;
						bottom: 0;
						margin-top: auto;
						margin-bottom: auto;
					}
				}
			}
		}
	}

	//Single problem page
	.page__inner {
		.single__problems--inner {
			padding: 50px 0;
			.content__wrap {
				margin-bottom: 50px;
				.right__content {
					h4 {
						font-size: 30px;
					}
				}
			}
		}
	}

	//Single product page
	.page__inner {
		.single__product {
			padding: 25px 0 0;
			.container {
				width: 100%;
			}
			.sidebar {
				#my-slider {
					margin-bottom: 25px;
				}
				.top__image {
					img {
						max-height: 220px;
					}
					.bottom__bg {
						height: 120px;
					}
				}
				.sp-thumbnails-container {
					margin-top: 20px;
				}
				.small__images {
					max-width: 450px;
					margin: 0 auto;
					margin-bottom: 30px;
				}
				.specifications {
					margin-bottom: 30px;
					.specifications__accordeon {
						.accordeon__top {
						    padding: 22px 0 21px 15px;
							font-size: 12px;
							&:before {
								right: 15px;
							}
						}
					}
				}
				.documents {
					margin-bottom: 50px;
					h4 {
						margin-bottom: 15px;
					}
					ul {
						li {
							a {
								padding: 20px 25px 60px 25px;
								text-align: center;
								&:before {
									bottom: 20px;
									left: 0;
									right: 0;
									margin-left: auto;
									margin-right: auto;
									margin-bottom: 0;
								}
							}
						}
					}
				}
			}
			.main__content {
				.table__title {
					margin-bottom: 15px;
				}
				.single__char {
					div {
						padding: 20px;
						h6 {
							width: 50%;
						}
						p {
							width: 50%;
						}
					}
				}
			}
			.single__product--bottom {
				img.alignright, img.alignleft {
					float: none;
					display: block;
					max-width: 90%;
					margin: 0 auto;
					margin-bottom: 20px;
				}
				img.aligncenter {
					max-width: 90%;
					margin-bottom: 30px;
				}
				p {
					margin-bottom: 20px;
				}
				ul {
					margin-bottom: 20px;
					li {
						line-height: 1.3;
						margin-bottom: 15px;
					}
				}
				.specifications__tabs {
					position: relative;
					margin: 50px 0;
					.sprec__tabs__items--wrap {
						margin-bottom: 5px;
				      .current__tab {
				        display: block;
				        font-size: 14px;
				        color: #fff;
				        text-align: center;
				        padding: 25px 0;
				        text-indent: -20px;
				        position: relative;
				        background-color: #6cb7df;
				        &:after {
				        	display: block;
				        	content:'';
				        	width: 0; 
							height: 0; 
							border-left: 4px solid transparent;
							border-right: 4px solid transparent;
							border-top: 7px solid #fff;
							position: absolute;
							right: 40px;
							top: 0;
							bottom: 0;
							margin-top: auto;
							margin-bottom: auto;
				        }
				      }
				    }
				}
				.sprec__tabs__items {
					margin-bottom: 0;
					background-color: #fff;
					display: none;
					position: absolute;
					width: 100%;
					left: 0;
					top: 70px;
					z-index: 10;
					li {
						width: 100%;
						font-size: 14px;
						background-color: #fff;
					}
				}
				.spec__tab--content--wrap {
					.spec__tab--content {
						.accordeon__top {
							font-size: 14px;
							height: 74px;
							display: -webkit-flex;
							display: -moz-flex;
							display: -ms-flex;
							display: -o-flex;
							display: flex;
							align-items: center;
							&:before {
								top: 30px;
							}
						}
						&:nth-child(2) {
							.accordeon__content {
								.content__row {
									.left, .right {
									    padding: 20px 15px 19px 20px;
									}
								}
							}
						}
						&:nth-child(3) {
							.accordeon__content {
								padding: 30px 20px;
							}
						}
					}
					.accordeon__content {
						padding: 30px 20px;
						p {
							margin-bottom: 15px;
						}
						ul {
							padding-left: 30px;
							margin-bottom: 20px;
							li {
								padding-left: 0;
								line-height: 1.3;
							}
						}
					}
				}
			}
			.prod__navigation--wrap {
				margin-top: 80px;
				margin-bottom: 80px;
				.prev {
					&:before {
						left: 0;
						right: 0;
						margin-left: auto;
						margin-right: auto;
						top: 0;
						bottom: 0;
						margin-top: auto;
						margin-bottom: auto;
					}
				}
				.next {
					&:before {
						left: 0;
						right: 0;
						margin-left: auto;
						margin-right: auto;
						top: 0;
						bottom: 0;
						margin-top: auto;
						margin-bottom: auto;
					}
				}
			}
		}

		//Single problem
		.single__problems--inner {
			.prod__navigation--wrap {
				margin-top: 80px;
				margin-bottom: 80px;
				.prev {
					&:before {
						left: 0;
						right: 0;
						margin-left: auto;
						margin-right: auto;
						top: 0;
						bottom: 0;
						margin-top: auto;
						margin-bottom: auto;
					}
				}
				.next {
					&:before {
						left: 0;
						right: 0;
						margin-left: auto;
						margin-right: auto;
						top: 0;
						bottom: 0;
						margin-top: auto;
						margin-bottom: auto;
					}
				}
			}
		}
	}

	//Navigation
	.prod__navigation--wrap {
		a {
			color: transparent !important;
			max-height: 70px !important;
		    background-position: center !important;
		    padding: 15px 0 !important;
		    span {
		    	display: none !important;
		    }
		}
		.prev {
			width: 50% !important;
		}
		.next {
			width: 50% !important;
		}
	}

	//Pagination
	.wp-pagenavi {
		padding-top: 0 !important;
		.current {
			margin-right: 10px !important;
			height: 40px !important;
		}
		a {
			margin-right: 10px !important;
			height: 43px !important;
		}
		.nextpostslink{
			margin-right: 0 !important;
			margin-left: 0 !important;
		}
	}

	//Popups
	.login__popup--wrap {
		overflow-y: scroll;
		.login__popup--inner {
			.popup {
				width: 100%;
				height: 100%;
				flex-direction: column;
				.left__image {
					width: 100%;
					max-height: 150px;
				}
				.right__form {
					width: 100%;
				    height: calc(100vh - 150px);
				    padding: 35px;
				}
			}
			.login__popup {
				.right__form {
					height: auto;
					background-color: #fff;
				}
			}
			.register__popup  {
				.right__form {
					height: auto;
					background-color: #fff;
				}
			}
		}
	}

.single__page--inner {

	ul.breadcrumbs {
		a {
			font-size: 12px !important;
		}
		li {
			 span {
				font-size: 12px !important;
			}
		}
	}
	.inner__banner {
		h1 {
			font-size: 26px !important;
			padding-right: 15px;
		}
	}
}	
}

@media all and (max-width: 400px) {
	.fourth__block {
		&:before {
			width: 126%;
			left: -50px;
		}
	}
}

@media all and (max-width: 350px) {
	//Page inner
	.page__inner {
		.inner__banner {
			padding-left: 20px;
			h1 {
				font-size: 36px;
			}
		}
	}
	//Error page
	.error404 {
		.error__inner {
			.page__wrap {
				h1 {
					font-size: 120px;
				}
				a {
					width: 240px;
					padding: 17px 0;
				}
			}
		}
	}
	//Page auctioneer
	.page__inner {
		.documentation__wrap {
			.documentation__table {
				tr {
					&:nth-child(2) {
						td {
						    width: 97.7%;
						}
					}
				}
			}
			.documentation__table--second {
				tr {
					td {
						font-size: 10px!important;
					}
				}
			}
			.more__documents {
				width: 97%;
			}
		}
		.auctioneer__page {
			.documentation__table {
				tr {
					th {
						&:before {
							display: none !important;
						}
					}
				}
			}
		}
	}
	//Page FAQ
	.page__inner {
		.offenq__accordeon {
			.accordeon__content {
				padding: 30px 25px;
			}
		}
	}

	//Page Partners
	.page__inner {
		.partners__inner {
			.map__inner {
				.map__point {
					width: 25px;
					height: 25px;
					.point__inner {
						width: 14px;
						height: 14px;
					}
				}
				.map__point--wrap {
					&:hover {
						.map__point {
							width: 50px;
							height: 50px;
							.point__inner {
								width: 30px;
								height: 30px;
								&:before {
									width: 20px;
									height: 20px;
								}
							}
						}
					}
					.point__logo {
						width: 150px;
						height: 50px;
						img {
							max-width: 50px;
						}
					}
					&.ternopol {
						top: 60px;
						left: 55px;
						&:hover {
						    top: 30px;
							left: 40px;
						}
					}
					&.vinnitsya {
						top: 75px;
						left: 100px;
						&:hover {
						    top: 45px;
							left: 90px;
						}
					}
					&.kiev {
						top: 45px;
						left: 125px;
						&:hover {
						    top: 15px;
							left: 110px;
						}
					}
					&.nikolaev {
						    bottom: 95px;
						    left: 155px;
						&:hover {
						    bottom: 125px;
						    left: 135px;
						}
					}
					&.poltava {
						        top: 60px;
						        right: 115px;
						&:hover {
						    right: 130px;
						    top: 25px;
						}
					}
				}
			}
		}
	}

	//Page rosources
	.page__inner {
		.resources__inner {
			.resources__tabs--wrap {
				.res__tab {
					font-size: 11px;
				}
			}
			.resources__tab--item  {
				.documentation__table {
					tr {
						th {
							&:before {
								right: 5px;
							}
						}
					}
				}
			}
		}
	}
	//Single product
	.page__inner {
		.single__product {
			.main__content {
				.sprec__tabs__items {
					li {
						font-size: 12px;
					}
				}
				.spec__tab--content--wrap {
					.accordeon__content {
						h6 {
							font-size: 16px;
						}
					}
				}
			}
		}
	}
}
